import React from "react";
import { CSSP_VERSION } from "../constants";

const Footer = () => {
  return (
    <footer className="bg-white fixed-bottom d-flex py-2 px-5">
      <div className="p-2 flex-grow-1">
        <small>
          &copy; {new Date().getFullYear()}{" "}
          <a href="https://languageio.com">Language IO, Inc.</a> | CSSP{" "}
          {CSSP_VERSION}
        </small>
      </div>
      <div className="p-2">
        <small className="mr-2">
          <a
            target="_blank"
            href="https://status.golinguist.com/"
            rel="noreferrer"
          >
            Service Status
          </a>
        </small>
        <small>
          <a
            target="_blank"
            href="https://languageio.com/about/terms/"
            rel="noreferrer"
          >
            Terms and Conditions
          </a>
        </small>
      </div>
    </footer>
  );
};

export default Footer;
