import { formatRequestData } from "../util";
import { StateSliceCreator } from "../../utils/types";
import {
  ParsingRule,
  ParsingRuleUpdateResponse,
} from "../../../types/ParsingRule";
import { STATUS } from "../../../../pages/settings/utils/GlossaryTableConstants";
import { SelectedLanguagePairT } from "./languagePairsSlice";
import { ConsumerAPI } from "../../../api/api-golinguist/consumerAPI";

export type ParsingRulesSliceT = {
  parsingRules: ParsingRule[];
  fetchParsingRules: (sourceLocaleId: number, targetLocaleId: number) => void;
  postParsingRules: (
    data: ParsingRule[],
    selectedLanguagePair: SelectedLanguagePairT
  ) => Promise<ParsingRuleUpdateResponse | undefined>;
  isLoading: boolean;
  resetParsingRulesTableData: () => void;
};

export const createParsingRulesSlice: StateSliceCreator<ParsingRulesSliceT> = (
  set,
  get
) => ({
  parsingRules: [],
  isLoading: false,
  fetchParsingRules: async (sourceLocaleId, targetLocaleId) => {
    if (get().parsingRules.length === 0) {
      set(() => ({ isLoading: true }));
    }
    const response = await ConsumerAPI.getParsingRules(
      sourceLocaleId,
      targetLocaleId
    );
    set(() => ({
      parsingRules: response?.data.map((i) => {
        i.status = STATUS.PUBLISHED;
        return i;
      }),
      isLoading: false,
    }));
  },
  postParsingRules: async (data, selectedLanguagePair) => {
    set(() => ({ isLoading: true }));
    const response = await ConsumerAPI.createParsingRules(
      formatRequestData(data, selectedLanguagePair)
    );
    set(() => ({ isLoading: false }));
    return response;
  },
  resetParsingRulesTableData: () => set(() => ({ parsingRules: [] })),
});
