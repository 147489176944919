import React, { useEffect, useState } from "react";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { useConsumerStore } from "../../../store/consumer";

const ConsumerToggle = () => {
  const {
    consumerOptions,
    fetchConsumerOptions,
    selectedConsumer,
    switchConsumer,
  } = useConsumerStore();

  const [consumer, setConsumer] = useState<string | null>(null);

  const handleOnChange = async (event: DropdownChangeEvent) => {
    setConsumer(event.value);
  };

  const reset = async () => {
    if (consumer) {
      await switchConsumer(consumer);
      location.reload();
    }
  };

  useEffect(() => {
    if (selectedConsumer) {
      setConsumer(selectedConsumer?.id ?? null);
    }
  }, [selectedConsumer]);

  useEffect(() => {
    fetchConsumerOptions();
  }, []);

  const onlyOneConsumerAvailable = consumerOptions.length === 1;

  return (
    <div className="px-6 py-2 bg-white d-flex consumer-toggle">
      {onlyOneConsumerAvailable ? (
        <div
          className="text-sm text-overflow-ellipsis border-1 border-gray-300 rounded p-inputtext p-inputtext-sm"
          style={{
            maxWidth: 350,
          }}
        >
          {selectedConsumer?.name}
        </div>
      ) : (
        <Dropdown
          value={consumer}
          onChange={(e) => handleOnChange(e)}
          optionLabel="name"
          optionValue="id"
          options={consumerOptions}
          style={{ minWidth: 170 }}
          onHide={() => reset()}
          className="dd-text-sm"
          /* panelClassName for drop down items class */
          panelClassName="dd-text-sm"
        />
      )}
    </div>
  );
};

export default ConsumerToggle;
