import React, { useEffect } from "react";
import { Card } from "primereact/card";
import { useConsumerStore } from "../../../../shared/store/consumer";
import APIKey from "./APIKey";
import { SUBMIT_SUPPORT_REQUEST_LINK } from "../../../../shared/constants/";

const APIKeyWrapper = () => {
  const { isLoading, apiKey, fetchApiKey, resetApiKeyCycle } =
    useConsumerStore();

  useEffect(() => {
    fetchApiKey();
    return resetApiKeyCycle;
  }, []);

  if (isLoading) return null;
  return (
    <Card className="mt-7 border">
      {apiKey ? (
        <APIKey></APIKey>
      ) : (
        <p className="my-8 text-lg">
          Please contact{" "}
          <a
            href={SUBMIT_SUPPORT_REQUEST_LINK}
            target="_blank"
            rel="noreferrer"
          >
            Language I/O support
          </a>{" "}
          for more details on how to manage your API Key.
        </p>
      )}
    </Card>
  );
};

export default APIKeyWrapper;
