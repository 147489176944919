/*
 fields are watch([
    "consumerName",
    "consumerEmail",
    "companyName",
    "consumerTitle",
  ]);
  return link should be like:
    "?firstname=joe&lastname=smith&email=test@abc.com&company=Lio company&jobtitle=Java developer"
*/
export function getHsformLink(fields: string[]): string {
  const name = fields[0]; //consumer name, will split to firstname and lastname. The first word will be firstname and the second word, if any, will be lastname
  if (name === undefined || name.trim().length == 0) return ""; //to prevent exception when page is loaded and no input for name
  const nameArray: string[] = name.split(" ", 2);
  const firstname = nameArray[0];
  const lastname = nameArray.length > 1 ? nameArray[1] : "";
  const email = fields[1];
  const company = fields[2];
  const jobtitle = fields[3];
  const link =
    "?firstname=" +
    firstname +
    "&lastname=" +
    lastname +
    "&email=" +
    email +
    "&company=" +
    company +
    "&jobtitle=" +
    jobtitle;
  return link;
}
