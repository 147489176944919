import React, { useEffect } from "react";
import { TabPanel } from "primereact/tabview";
import GlossaryTabBody from "./glossary/GlossaryTabBody";
import { useConsumerStore } from "../../../shared/store/consumer";
import LanguagesTabBody from "./languages/LanguagesTabBody";
import {
  Permission,
  PermissionGroup,
} from "../../../shared/types/UserPermission";
import TabViewThatWorksWithPermissions from "../../../shared/components/TabViewThatWorksWithPermissions";

const SettingsPage = () => {
  const { currentConsumer, fetchCurrentConsumer, hasPermission } =
    useConsumerStore();

  useEffect(() => {
    fetchCurrentConsumer();
  }, []);

  return (
    <div className="container">
      <div className="row justify-content-center">
        {currentConsumer.encryptedSelectedConsumerId && (
          <TabViewThatWorksWithPermissions className="col-lg-11">
            <TabPanel header="Languages">
              <LanguagesTabBody />
            </TabPanel>
            {hasPermission(PermissionGroup.GLOSSARY, Permission.VIEW) && (
              <TabPanel header="Glossary">
                <GlossaryTabBody />
              </TabPanel>
            )}
          </TabViewThatWorksWithPermissions>
        )}
      </div>
    </div>
  );
};

export default SettingsPage;
