import React, { useRef, useState } from "react";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";

type DropdownWithAddNewPropsT = {
  options: string[];
  onChangeCb: (...event: any[]) => void;
  focusInputRef: any;
  name: string;
  value: any;
  className?: string;
  pt: { [index: string]: any };
};

const addNewOptionValue = "add new";
const addTeamLabel = " (New team)";

const DropdownWithAddNew = ({
  options,
  name,
  value,
  focusInputRef,
  onChangeCb,
  className,
  pt,
}: DropdownWithAddNewPropsT) => {
  const [addedOptions, setAddedOptions] = useState<string[]>([]);
  const [customValue, setCustomValue] = useState("");
  const dropdownRef = useRef<Dropdown>(null);
  const dropdownInputRef = useRef<HTMLSelectElement>(null);

  const mappedOptions = [...options, ...addedOptions].map((opt) => ({
    value: opt,
    label: opt,
  }));

  const isUniqueDropdownValue = mappedOptions.every(
    (opt) => opt.label !== value
  );

  const _options = (() => {
    if (value && isUniqueDropdownValue) {
      const filteredOptions = mappedOptions.filter((opt) =>
        opt.label.toLowerCase().startsWith(value.toLowerCase())
      );
      return [
        ...filteredOptions,
        { label: value + addTeamLabel, value: addNewOptionValue },
      ];
    }
    return mappedOptions;
  })();

  const onDropdownFocus = () => {
    // using dropdownRef.current?.show() instead of dropdownRef.current?.getElement().click()
    // because getElement().click() is not working as expected in Firefox
    // ***
    // ts-ignore here because for some reason they don't have this method in TS types,
    // but the method is in the documentation and available at runtime.
    // I also hardcoded version number of primereact dependency to the version where this method is present to avoid possible runtime errors.
    // @ts-ignore
    dropdownRef.current?.show();
    dropdownInputRef.current?.focus();
  };

  const getItemTemplate = (opt: { label: string; value: string }) => {
    if (isUniqueDropdownValue && opt.value === addNewOptionValue) {
      return value + addTeamLabel;
    }
    return opt.label;
  };

  const addNewOption = () => {
    // adding 100ms delay here to make UI smoother
    setTimeout(() => {
      onChangeCb(customValue);
      setAddedOptions((prev) => [...prev, customValue]);
    }, 100);
  };

  const onChange = (e: DropdownChangeEvent) => {
    if (e.originalEvent) {
      // e.originalEvent will be truthy when dropdown options was selected
      // at this point we would want to stop focusing input element for text input

      // for some reason `dropdownInputRef.current?.blur()` would not work without setTimeout
      // (I assume it's because input is re-focused somewhere in primereact code after code in this function is executed)
      // setTimeout(fn, 0) just the adds a new frame to event loop queue, basically making sure `fn` runs after
      // everything is current execution stack
      setTimeout(() => dropdownInputRef.current?.blur(), 0);
    }

    if (e.value === addNewOptionValue) {
      return addNewOption();
    }
    setCustomValue(e.value);
    onChangeCb(e.value);
  };

  return (
    <Dropdown
      id={name}
      focusInputRef={focusInputRef}
      pt={pt}
      ref={dropdownRef}
      onFocus={onDropdownFocus}
      inputRef={dropdownInputRef}
      editable
      itemTemplate={getItemTemplate}
      options={_options}
      optionLabel="label"
      value={value}
      onChange={onChange}
      className={className}
      emptyMessage="Start typing to add"
    />
  );
};

export default DropdownWithAddNew;
