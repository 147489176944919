import React from "react";
import { getGoLinguistDomain } from "../../../../api/utils";
import { useConsumerStore } from "../../../../store/consumer";
import { Permission, PermissionGroup } from "../../../../types/UserPermission";

export const SERVER_CODE_MT_PORTAL_URL =
  getGoLinguistDomain() + "linguistnow/consumer/machine-translations";

const NavServerCode = () => {
  const { hasPermission } = useConsumerStore();

  return (
    <div className="d-flex flex-grow-1 justify-content-center flex-wrap">
      {hasPermission(PermissionGroup.MT_PORTAL, Permission.USE) && (
        <a href={SERVER_CODE_MT_PORTAL_URL} className="nav-link">
          <strong>Machine Translation Portal</strong>
        </a>
      )}
      {hasPermission(PermissionGroup.PROJECT, Permission.VIEW) && (
        <a
          href={
            getGoLinguistDomain() + "linguistnow/consumer/project-management"
          }
          className="nav-link"
        >
          <strong>Projects</strong>
        </a>
      )}
      <a
        href="https://languageio-ss.domo.com/"
        className="nav-link fw-bold"
        target="_blank"
        rel="noreferrer"
      >
        Reports
      </a>
      <a
        href="https://support.languageio.com/"
        className="nav-link fw-bold"
        target="_blank"
        rel="noreferrer"
      >
        Support
      </a>
    </div>
  );
};

export default NavServerCode;
