export const SUBMIT_SUPPORT_REQUEST_LINK =
  "https://languageio.zendesk.com/hc/en-us/requests/new";

export const FORMALITY_TYPES = {
  FORMAL: { value: "FORMAL", label: "Formal" },
  INFORMAL: { value: "INFORMAL", label: "Informal" },
  LIO_DEFAULT: { value: "LIO_DEFAULT", label: "" },
} as const;

export const NO_LANGUAGE_SELECTED = 0;

export const CSSP_VERSION = "1.1.0";

export const GLOSSARY_TABLE_COLUMN_FIELD = {
  source: "sourceString",
  replacement: "replacementString",
  caseInsensitive: "caseInsensitive",
  applyNoTranslate: "applyNoTranslateTag",
  status: "status",
};

export const ACTION_STATUS = {
  create: "CREATE",
  update: "UPDATE",
  delete: "DELETE",
} as const;

export const USER_STATUS_NAME_MAPPING = {
  PENDING: "Pending",
  ACTIVE: "Active",
  DISABLED: "Disabled",
} as const;

export const ADMIN_ROLE_ID = 1;
