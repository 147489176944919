import axios, { AxiosError, CreateAxiosDefaults } from "axios";
import { hideLoadingOverlay } from "../functions/utils";
import { getGoLinguistDomain } from "./utils";
import { ServerErrorResp } from "../types/ServerError";
import useAppErrorsState from "../store/app-errors";

const isFirefox = () =>
  navigator.userAgent.toLowerCase().indexOf("firefox") > -1;

const errorHandler = (errResp: AxiosError<ServerErrorResp>) => {
  const statusCode = errResp.response?.status;

  /*
   * https://languageio.atlassian.net/browse/SV-331
   * Issue specific to Firefox: when the page reloads, any ongoing HTTP requests in axios are abruptly halted, causing a brief flash of the error dialog we implemented.
   * This issue does not occur in Safari or Chromium-based browsers like Chrome and Edge.
   *
   * Currently, the page reload logic is implemented only in the ConsumerToggle component within the onSwitchConsumer function.
   * A page reload is triggered to fetch updated data from the server after switching consumers.
   */
  if (isFirefox()) {
    if (errResp.code === "ECONNABORTED") {
      return;
    }
  }

  if (statusCode && statusCode === 401) {
    window.location.href = getGoLinguistDomain() + "linguistnow/login";
    return;
  }

  useAppErrorsState
    .getState()
    .setServerError(errResp.response?.data.error, statusCode);

  hideLoadingOverlay();
  // throw error for custom error handling up the stack
  throw errResp;
};

export const createAxiosInstance = (config: CreateAxiosDefaults) => {
  const instance = axios.create(config);
  instance.interceptors.response.use(undefined, errorHandler);

  return instance;
};
