import { apiGateway } from "./index";
import { TranslationRequestBody } from "../../types/ApiTranslation/TranslationRequestBody";
import { TranslationResponseBody } from "../../types/ApiTranslation/TranslationResponseBody";

export const TranslationAPI = {
  translate: async (data: TranslationRequestBody, apiKey: string) => {
    return apiGateway<TranslationResponseBody>({
      url: "/linguistnow/resources/v1/translate",
      method: "POST",
      headers: {
        "x-api-key": apiKey,
      },
      data,
    });
  },
};
