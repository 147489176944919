import {
  ParsingRule,
  ParsingRuleTransactionRequest,
} from "../../types/ParsingRule";
import { STATUS } from "../../../pages/settings/utils/GlossaryTableConstants";
import { SelectedLanguagePairT } from "./slices/languagePairsSlice";
import { ACTION_STATUS } from "../../constants";

export const formatRequestData = (
  data: ParsingRule[],
  selectedLanguagePair: SelectedLanguagePairT
): ParsingRuleTransactionRequest => {
  return {
    targetLocaleId: selectedLanguagePair?.targetLocaleId || 0,
    sourceLocaleId: selectedLanguagePair?.sourceLocaleId || 0,
    parsingRuleRequestItems: data.map((parsingRule) => {
      const processedItem = {
        ...parsingRule,
      };

      if (parsingRule.status === STATUS.NEW_TERM) {
        delete processedItem.id;
        processedItem.status = ACTION_STATUS.create;
      } else if (parsingRule.status === STATUS.DELETE) {
        processedItem.status = ACTION_STATUS.delete;
      } else {
        processedItem.status = ACTION_STATUS.update;
      }

      return processedItem;
    }),
  };
};
