import { ParsingRule } from "../../../shared/types/ParsingRule";
import { STATUS } from "./GlossaryTableConstants";
import { SelectedLanguagePairT } from "../../../shared/store/glossary/slices/languagePairsSlice";
import { GLOSSARY_TABLE_COLUMN_FIELD } from "../../../shared/constants";

export const getStatusLabel = (rowData: ParsingRule) => {
  if (!rowData.status) {
    return STATUS.DEFAULT;
  } else {
    return rowData.status;
  }
};

export const getColumns = (selectedLanguagePair: SelectedLanguagePairT) => {
  return [
    {
      field: GLOSSARY_TABLE_COLUMN_FIELD.source,
      header: selectedLanguagePair
        ? "Source: " + selectedLanguagePair?.sourceLocaleName
        : "Source:",
      filterPlaceholder: selectedLanguagePair
        ? "Search " + selectedLanguagePair?.sourceLocaleName
        : "Search",
    },
    {
      field: GLOSSARY_TABLE_COLUMN_FIELD.replacement,
      header: selectedLanguagePair
        ? "Replacement: " + selectedLanguagePair?.targetLocaleName
        : "Replacement:",
      filterPlaceholder: selectedLanguagePair
        ? "Search " + selectedLanguagePair?.targetLocaleName
        : "Search",
    },
    {
      field: GLOSSARY_TABLE_COLUMN_FIELD.caseInsensitive,
      header: "Case Insensitive",
      dataType: "boolean",
    },
    {
      field: GLOSSARY_TABLE_COLUMN_FIELD.applyNoTranslate,
      header: "No Mutate",
      dataType: "boolean",
    },
    {
      field: GLOSSARY_TABLE_COLUMN_FIELD.status,
      header: "Status",
    },
  ];
};

export const getBodyClassName = (field: string) => {
  switch (field) {
    case GLOSSARY_TABLE_COLUMN_FIELD.applyNoTranslate:
    case GLOSSARY_TABLE_COLUMN_FIELD.caseInsensitive:
      return "text-center";
  }
};

export const compareLocaleSensitive = (
  a: string,
  b: string,
  locale?: string,
  numeric?: boolean,
  order?: 1 | -1
) => {
  const comparisonResult = a.localeCompare(b, locale, { numeric });
  let returnVal = 0;
  if (!order || order === 1) {
    // localeCompare can return any positive or negative integer or 0
    if (comparisonResult > 0) {
      returnVal = 1;
    }
    if (comparisonResult < 0) {
      returnVal = -1;
    }
  } else {
    // invert values when order == -1
    if (comparisonResult > 0) {
      returnVal = -1;
    }
    if (comparisonResult < 0) {
      returnVal = 1;
    }
  }

  return returnVal;
};
