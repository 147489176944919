import React, { ReactNode } from "react";
import { Checkbox, CheckboxChangeEvent } from "primereact/checkbox";
import "./style.css";
import { ItemT } from "./index";

type PropsT = {
  className?: string;
  item: ItemT;
  onChange: (id: number | string, e: CheckboxChangeEvent) => void;
  disabledOverride?: boolean;
  children?: ReactNode;
};
const Item = (props: PropsT) => {
  const { item } = props;
  return (
    <label
      className={`checkbox-select-item d-flex align-items-center px-4 py-3 border-bottom cursor-pointer hover:bg-gray-100${
        props.className ? " " + props.className : ""
      }`}
    >
      <Checkbox
        disabled={props.disabledOverride || item.disabled}
        className="mr-3"
        onChange={(e) => props.onChange(item.id, e)}
        checked={item.checked}
      />
      <span className="select-none">{item.name}</span>
      {props.children}
    </label>
  );
};

export default Item;
