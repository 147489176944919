import React, { useCallback, useEffect, useState } from "react";
import { Divider } from "primereact/divider";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import "./style.css";
import { useMTPortalStore } from "../../shared/store/machine-translation-portal";
import { NO_LANGUAGE_SELECTED } from "../../shared/constants";
import { useLocalesStore } from "../../shared/store/locales";
import useLocalePairSelection from "../../shared/hooks/useLocalePairSelection";
import { useConsumerStore } from "../../shared/store/consumer";
import { TranslationAPI } from "../../shared/api/api-gateway/translationAPI";

const MTPortal = () => {
  const { apiKey, fetchApiKey } = useConsumerStore();
  const { consumerHasLocales, fetchConsumerHasLocales } = useMTPortalStore();
  const { locales, fetchLocales } = useLocalesStore();
  useEffect(() => {
    fetchLocales();
    fetchConsumerHasLocales();
    fetchApiKey();
  }, []);

  const [sourceText, setSourceText] = useState<string>("");
  const [translatedText, setTranslatedText] = useState<string>("");

  const {
    sourceLocaleOptions,
    targetLocaleOptions,
    selectedSourceLocaleId,
    setSelectedSourceLocaleId,
    selectedTargetLocaleId,
    setSelectedTargetLocaleId,
  } = useLocalePairSelection(consumerHasLocales, locales);

  const onSourceLocaleSelect = (e: DropdownChangeEvent) => {
    setSelectedSourceLocaleId(e.value);
    setSelectedTargetLocaleId(NO_LANGUAGE_SELECTED);
  };

  const onTargetLocaleSelect = (e: DropdownChangeEvent) => {
    setSelectedTargetLocaleId(e.value);
  };

  const clearInput = () => {
    setSourceText("");
    setTranslatedText("");
  };

  const translate = useCallback(async () => {
    const findConsumerLocaleId = (id: number) => {
      return consumerHasLocales.find((val) => val.localeId === id)
        ?.consumerLocaleId;
    };

    const sourceLocale = findConsumerLocaleId(selectedSourceLocaleId);
    const targetLocale = findConsumerLocaleId(selectedTargetLocaleId);
    if (!sourceLocale || !targetLocale) {
      throw new Error("Can not find selected source or target locale name");
    }

    const response = await TranslationAPI.translate(
      {
        sourceContent: sourceText,
        sourceLocale,
        targetLocale,
        contentTypeName: "api",
        translationType: "machine",
        textType: "text",
      },
      apiKey
    );

    setTranslatedText(response.translatedText);
  }, [sourceText, apiKey, selectedSourceLocaleId, selectedTargetLocaleId]);

  return (
    <div className="mt-portal">
      <h1 className="page-header mb-5">Machine Translation Portal</h1>
      <div className="text-sm lh-base page-description">
        <p>
          Some text here to introduce the purpose of this section or tool. Also
          maybe a link to a help guide or email for support link?
        </p>
      </div>
      <Divider />
      <div className="grid text-sm">
        <div className="col-6">
          <Dropdown
            className="w-100 dd-text-sm"
            /* panelClassName for drop down items class */
            panelClassName="dd-text-sm"
            options={sourceLocaleOptions}
            onChange={onSourceLocaleSelect}
            value={selectedSourceLocaleId}
            placeholder="Source language"
          />
        </div>
        <div className="col-6">
          <Dropdown
            className="w-100 dd-text-sm"
            /* panelClassName for drop down items class */
            panelClassName="dd-text-sm"
            options={targetLocaleOptions}
            onChange={onTargetLocaleSelect}
            value={selectedTargetLocaleId}
            placeholder="Target language"
            disabled={!selectedSourceLocaleId}
          />
        </div>
        <div className="col relative">
          {sourceText && (
            <div className="clear-textarea absolute">
              <button onClick={clearInput}>
                <i className="pi pi-times text-sm"></i>
              </button>
            </div>
          )}
          <InputTextarea
            className="w-100 pr-6"
            value={sourceText}
            onChange={(e) => setSourceText(e.target.value)}
            rows={9}
            cols={50}
          />
        </div>
        <div className="col">
          <InputTextarea
            className="w-100"
            value={translatedText}
            rows={9}
            cols={50}
          />
        </div>
        <div className="col-12">
          <Button
            onClick={translate}
            disabled={
              !selectedSourceLocaleId || !selectedTargetLocaleId || !sourceText
            }
          >
            Translate
          </Button>
        </div>
      </div>
    </div>
  );
};
export default MTPortal;
