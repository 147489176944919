import { Button } from "primereact/button";
import { getDataTestIdPtObject } from "../../../../shared/functions/utils";
import React from "react";
import { useUsersStore } from "../../../../shared/store/users";
import { DBUser } from "../../../../shared/types/User";
import { ADMIN_ROLE_ID } from "../../../../shared/constants";

export interface DisableUserProps {
  selectedUserIds: number[];
  onDisable: () => void;
  showDisableConfirmationMessage: boolean;
  setShowDisableConfirmationMessage: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  selectedItems?: DBUser[];
  showUnableToProcessDialog: () => void;
}

const DisableUser = (props: DisableUserProps) => {
  const { disableUser, activeUsers } = useUsersStore();

  const handleUserDisable = () => {
    const selectedUsersAreTheOnlyAdmins = activeUsers().every(
      (user, index, array) => {
        const isSelectedUser = props.selectedUserIds.indexOf(user.id) > -1;
        const isNotAdminUser = user.role.id !== ADMIN_ROLE_ID;
        return isSelectedUser || isNotAdminUser;
      }
    );

    if (selectedUsersAreTheOnlyAdmins) {
      props.showUnableToProcessDialog();
    } else {
      disableUser(props.selectedUserIds);
    }

    props.setShowDisableConfirmationMessage(false);
    props.onDisable();
  };

  return (
    <>
      {props.showDisableConfirmationMessage && (
        <div className="m-0 text-center py-3">
          <div className="flex align-items-center justify-content-center gap-2">
            <span>
              {`Are you sure you want to disable ${
                props.selectedItems && props.selectedItems.length > 1
                  ? "these users"
                  : "this user"
              }?`}
            </span>
            <Button
              size="small"
              onClick={handleUserDisable}
              pt={{ root: getDataTestIdPtObject("disableYesBtn") }}
            >
              Yes
            </Button>
            <Button
              size="small"
              onClick={() => props.setShowDisableConfirmationMessage(false)}
              pt={{ root: getDataTestIdPtObject("disableNoBtn") }}
            >
              No
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default DisableUser;
