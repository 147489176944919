import React from "react";
import { useUsersStore } from "../../../../shared/store/users";
import AddAndEditUserModal, { UserFormData } from "./AddAndEditUserModal";

type PropsT = {
  teams: string[];
  isAddUserOpen: boolean;
  setIsAddUserOpen: React.Dispatch<React.SetStateAction<boolean>>;
};
const AddUser = (props: PropsT) => {
  const { createUser } = useUsersStore();

  const onSubmit = ({
    firstName,
    lastName,
    email,
    team,
    role,
  }: UserFormData) => {
    const createUserPayload = {
      firstName,
      lastName,
      email,
      team,
      roleId: role.id,
    };
    createUser(createUserPayload);
  };

  return (
    <AddAndEditUserModal
      teams={props.teams}
      isOpen={props.isAddUserOpen}
      setIsOpen={props.setIsAddUserOpen}
      onSubmit={onSubmit}
    />
  );
};

export default AddUser;
