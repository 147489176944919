import { AxiosInstance, AxiosRequestConfig } from "axios";
import { hideLoadingOverlay, showLoadingOverlay } from "../functions/utils";

export const createApiAccessor =
  (instance: AxiosInstance) =>
  async <T>(requestConfig: AxiosRequestConfig) => {
    showLoadingOverlay();
    const response = await instance.request<T>(requestConfig);
    hideLoadingOverlay();
    return response.data;
  };

export function getAPIDomain(): string {
  if (window.location.href.startsWith("https://golinguist")) {
    return "https://api.golinguist.com/";
  }
  if (window.location.href.startsWith("https://int.golinguist")) {
    return "https://apiint.golinguist.com/";
  }
  if (window.location.href.startsWith("https://smoke.golinguist")) {
    return "https://apismoke.golinguist.com/";
  }
  if (window.location.href.startsWith("https://stage.golinguist")) {
    return "https://apistage.golinguist.com/";
  }
  if (window.location.href.startsWith("http://localhost")) {
    return "http://localhost:8081/";
  }
  if (
    window.location.href.startsWith("https://dev-fn.golinguist") ||
    window.location.href.startsWith("https://dev-fn-55d5bb59af.golinguist.com")
  ) {
    return "https://apidev-fn.golinguist.com/";
  }

  return "https://apidev.golinguist.com/";
}

export function getGoLinguistDomain(): string {
  const url = new URL(window.location.href);
  return url.origin + "/";
}

export function getApiGatewayDomain() {
  if (window.location.href.startsWith("https://golinguist")) {
    return "https://agw.golinguist.com/";
  }
  if (window.location.href.startsWith("https://smoke.golinguist")) {
    return "https://smoke-agw.golinguist.com/";
  }
  if (window.location.href.startsWith("https://stage.golinguist")) {
    return "https://stage-agw.golinguist.com/";
  }

  return "https://dev-agw.golinguist.com/";
}
