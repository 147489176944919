import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  TabView,
  TabViewProps,
  TabViewTabChangeEvent,
} from "primereact/tabview";

const TabViewThatWorksWithPermissions = (props: TabViewProps) => {
  // This component is needed when props.children is like [false, false, ReactElement, false]
  // in such case we filter out booleans to allow for active tab to be selected correctly.
  // Explanation:
  // we have this code
  // {hasPermission(PermissionGroup.API_KEY, Permission.VIEW) && (
  //  <TabPanel header="API Key">
  //    <APIKey />
  //  </TabPanel>
  // )}
  // during runtime it results in either false or TabPanel component object.
  // There is a bug in TabView component where it counts false as a tab and when activeIndex is 0 and the TabView has
  // this false value at tabs index 0, it does think false is the active tab and won’t display the real tab in that case.
  // The solution for this is to simply filter out false values from TabView children.
  const renderChildren = Array.isArray(props.children)
    ? props.children.filter((value) => value)
    : props.children;

  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  const PARAM_NAME = "tab";

  const [activeTabIndex, setActiveTabIndex] = useState(0);

  useEffect(() => {
    if (Array.isArray(renderChildren)) {
      const tabIndexFromUrl =
        parseInt(new URLSearchParams(search).get(PARAM_NAME) || "") || 0;

      let activeIndexFromUrl = tabIndexFromUrl;

      if (tabIndexFromUrl > renderChildren.length - 1) {
        activeIndexFromUrl = 0;
        navigate(pathname);
      }

      setActiveTabIndex(activeIndexFromUrl);
    }
  }, [renderChildren]);

  const onTabChange = (e: TabViewTabChangeEvent) => {
    setActiveTabIndex(e.index);
    if (e.index === 0) {
      navigate(pathname);
    } else {
      navigate(pathname + `?${PARAM_NAME}=` + e.index);
    }
  };

  return (
    <TabView activeIndex={activeTabIndex} onTabChange={onTabChange}>
      {renderChildren}
    </TabView>
  );
};

export default TabViewThatWorksWithPermissions;
