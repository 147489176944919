import React, { useEffect, useRef, useState } from "react";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { Tooltip } from "primereact/tooltip";
import { FieldHeader, TabHeader } from "../shared/Headers";
import { useConsumerStore } from "../../../../shared/store/consumer";
import { Divider } from "primereact/divider";
import { Button } from "primereact/button";
import { SUBMIT_SUPPORT_REQUEST_LINK } from "../../../../shared/constants/";
import {
  Permission,
  PermissionGroup,
} from "../../../../shared/types/UserPermission";

const DOMAIN = "agw.golinguist.com";

const APIKey = () => {
  const copyKeyToolTipRef = useRef<Tooltip>(null);
  const copyDomainToolTipRef = useRef<Tooltip>(null);
  const copyDebugIdToolTipRef = useRef<Tooltip>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hideApiKey, setHideApiKey] = useState(true);
  const {
    apiKey,
    allowApiKeyCycle,
    cycleApiKey,
    hasPermission,
    currentConsumer,
  } = useConsumerStore();

  const onToggleShowApiKey = () => {
    setHideApiKey((prev) => !prev);
  };
  const copyApiKey = () => {
    window.navigator.clipboard.writeText(apiKey);
    copyKeyToolTipRef.current?.hide();
  };
  const onCycleApi = () => {
    setIsLoading(true);
    cycleApiKey();
  };

  return (
    <>
      <TabHeader className="mb-5">Authentication Key</TabHeader>
      <FieldHeader>API Key</FieldHeader>

      <div className="field-input p-input-icon-right mt-1 w-100">
        <Tooltip
          ref={copyKeyToolTipRef}
          position="top"
          className="text-sm"
          target=".copy-btn"
        >
          Copy
        </Tooltip>
        <Tooltip
          ref={copyKeyToolTipRef}
          position="top"
          className="text-sm"
          target=".toggle-show-btn"
        >
          {hideApiKey ? "Show" : "Hide"}
        </Tooltip>
        <i
          onClick={onToggleShowApiKey}
          className="toggle-show-btn pi pi-eye text-gray-900 cursor-pointer hover:bg-black-alpha-10 rounded-1 text-lg"
        />
        <i
          onClick={copyApiKey}
          className="copy-btn pi pi-copy text-gray-900 cursor-pointer hover:bg-black-alpha-10 rounded-1 text-lg"
        />
        <InputText
          className="w-100 border-gray-400 bg-gray-50"
          disabled
          value={apiKey}
          type={hideApiKey ? "password" : undefined}
        />
      </div>
      <FieldHeader>Domain</FieldHeader>
      <div className="field-input p-input-icon-right mt-1 w-100">
        <Tooltip
          ref={copyDomainToolTipRef}
          position="top"
          className="text-sm"
          target=".copy-btn"
        >
          Copy
        </Tooltip>
        <i
          onClick={() => {
            window.navigator.clipboard.writeText(DOMAIN);
            copyDomainToolTipRef?.current?.hide();
          }}
          className="copy-btn pi pi-copy text-gray-900 cursor-pointer hover:bg-black-alpha-10 rounded-1 text-lg"
        />
        <InputText
          className="w-100 border-gray-400 bg-gray-50"
          disabled
          value={DOMAIN}
        />
      </div>
      <FieldHeader>Debug ID</FieldHeader>
      <div className="field-input p-input-icon-right mt-1 w-100">
        <Tooltip
          ref={copyDebugIdToolTipRef}
          position="top"
          className="text-sm"
          target=".copy-btn"
        >
          Copy
        </Tooltip>
        <i
          onClick={() => {
            window.navigator.clipboard.writeText(
              currentConsumer.encryptedSelectedConsumerId
            );
            copyDebugIdToolTipRef?.current?.hide();
          }}
          className="copy-btn pi pi-copy text-gray-900 cursor-pointer hover:bg-black-alpha-10 rounded-1 text-lg"
        />
        <InputText
          className="w-100 border-gray-400 bg-gray-50"
          disabled
          value={currentConsumer.encryptedSelectedConsumerId}
        />
      </div>
      <p className="text-sm font-italic line-height-1 mt-3 text-secondary">
        Note: This API key is only meant to provide access to Language I/O
        translation services for third-party applications. For details on how to
        connect Language I/O CRM integrations, please contact{" "}
        <a href={SUBMIT_SUPPORT_REQUEST_LINK} target="_blank" rel="noreferrer">
          Language I/O support.
        </a>
      </p>
      <Divider></Divider>
      <div className="flex justify-content-end">
        {hasPermission(PermissionGroup.API_KEY, Permission.EDIT) && (
          <Button
            icon={isLoading && allowApiKeyCycle && "pi pi-spin pi-spinner"}
            disabled={isLoading || !allowApiKeyCycle}
            onClick={onCycleApi}
            label="Generate new API key"
          ></Button>
        )}
      </div>
    </>
  );
};

export default APIKey;
