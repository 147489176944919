import React, { useCallback, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import "./style.css";
import { Locale } from "../../types/Locale";
import { FormalityT } from "../../types/Formality";
import CheckboxMultiSelect, { ItemT } from "../CheckboxMultiSelect";
import { FORMALITY_TYPES } from "../../constants";
import { ConsumerHasLocale } from "../../types/ConsumerHasLocale";
import { getDataTestIdPtObject } from "../../functions/utils";

type ExtendLocaleItemT = {
  consumerHasLocaleId?: number;
  consumerHasLocale?: boolean;
  formalityType?: FormalityT;
  isFormalitySupported?: boolean;
};

export type ExtendLocaleT = Locale & ExtendLocaleItemT;
type ExtendedItemT = ItemT & ExtendLocaleItemT;
type LanguageSelectPropsT = {
  languages: Array<ExtendLocaleT>;
  selectedItems?: Array<ConsumerHasLocale>;
  setSelectedItems: React.Dispatch<
    React.SetStateAction<Array<ConsumerHasLocale>>
  >;
  disabled?: boolean;
  className?: string;
};

const formalityOptions = [FORMALITY_TYPES.FORMAL, FORMALITY_TYPES.INFORMAL];

const LanguagesSelect = (props: LanguageSelectPropsT) => {
  const [filterValue, setFilterValue] = useState("");
  const [selectItems, setSelectItems] = useState<Array<ExtendedItemT>>([]);

  React.useEffect(() => {
    setSelectItems(
      props.languages.map(
        ({
          id,
          mtDisplayName,
          consumerHasLocale,
          formalityType,
          isFormalitySupported,
          consumerHasLocaleId,
        }) => {
          const isSelected = !!props.selectedItems?.find(
            (item) => item.localeId === id
          );
          return {
            id,
            consumerHasLocaleId,
            name: mtDisplayName || "",
            checked: isSelected || !!consumerHasLocale,
            disabled: !!consumerHasLocale,
            formalityType,
            isFormalitySupported,
          };
        }
      )
    );
  }, [props.languages, props.selectedItems]);

  const onItemSelectCallBack = (item: ExtendedItemT | ExtendedItemT[]) => {
    if (Array.isArray(item)) {
      // multiple languages added
      props.setSelectedItems(
        item
          // when using "Select all", only select locales that consumer does not have
          .filter((el) => !el.consumerHasLocaleId)
          .map((el) => ({
            ...el,
            localeId: el.id,
          }))
      );
      return;
    }

    if (item.checked) {
      // one language added, or Formality Type updated
      props.setSelectedItems((prevState) => {
        const selectedItemIndex = prevState.findIndex(
          ({ localeId }) => localeId === item.id
        );
        const newItem = {
          ...item,
          localeId: item.id,
        };

        if (selectedItemIndex !== -1) {
          prevState.splice(selectedItemIndex, 1, newItem);
        } else {
          prevState.push(newItem);
        }

        return [...prevState];
      });
      return;
    }

    // one language removed
    props.setSelectedItems((prevState) => {
      return prevState.filter((el) => el.localeId !== item.id);
    });
  };

  const getFormalityCheckbox = useCallback(
    (id: number) => {
      const item = selectItems.find(({ id: itemId }) => itemId === id);
      return (
        item?.checked &&
        item?.isFormalitySupported && (
          <div className="ml-auto" onClick={(e) => e.preventDefault()}>
            <Dropdown
              pt={{
                root: getDataTestIdPtObject("formalityDropdown"),
                list: getDataTestIdPtObject("formalityDropdownList"),
              }}
              className="dd-text-sm formalityDropdown"
              /* panelClassName for drop down items class */
              panelClassName="dd-text-sm"
              onChange={(e) => {
                onItemSelectCallBack({ ...item, formalityType: e.value });
                setSelectItems((prevState) => {
                  const selectItemIndex = prevState.findIndex(
                    ({ id: itemId }) => itemId === id
                  );
                  const newItem = {
                    ...prevState[selectItemIndex],
                    formalityType: e.value,
                  };

                  prevState.splice(selectItemIndex, 1, newItem);

                  return [...prevState];
                });
              }}
              options={formalityOptions}
              value={item?.formalityType}
              disabled={props.disabled}
            />
          </div>
        )
      );
    },
    [selectItems, props.selectedItems, props.disabled]
  );

  return (
    <div>
      <p className="filter-text mb-1 text-gray-700 text-sm">Filter languages</p>
      <div className="filter-input-wrapper p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          value={filterValue}
          onChange={(e) => setFilterValue(e.target.value)}
          placeholder="Type to filter list below"
          className="w-100 pr-4"
        />
        <i
          onClick={() => setFilterValue("")}
          className="pi pi-times close-btn p-1 hover:bg-gray-100 fw-bold rounded-circle"
          role="button"
        />
      </div>
      <div className={"mt-4 overflow-y-scroll " + props.className}>
        <CheckboxMultiSelect
          filterValue={filterValue}
          allDisabled={
            props.disabled || selectItems.every((item) => item.disabled)
          }
          onChangeCb={onItemSelectCallBack}
          setItems={setSelectItems}
          items={selectItems}
          headerItemChildren={
            <span className="formality-label ml-auto text-sm user-select-none">
              Formality
            </span>
          }
          itemChildren={getFormalityCheckbox}
        />
      </div>
    </div>
  );
};

export default LanguagesSelect;
