import { HTMLAttributes } from "react";

export function showLoadingOverlay(): void {
  showHideLoadingOverlay(true);
}

export const hideLoadingOverlay = debounce(
  () => showHideLoadingOverlay(false),
  250
);

function showHideLoadingOverlay(show: boolean): void {
  const loadingOverlay = document.getElementById("loading-overlay");
  let displayStyle = "none";
  if (show) displayStyle = "block";
  if (loadingOverlay !== null) loadingOverlay.style.display = displayStyle;
}

// The following PrimereactPassThroughCastT type is used to cast 'pt' prop in primereact components to allow to pass custom attributes;
type PrimereactPassThroughCastT = HTMLAttributes<any>;
export const getDataTestIdPtObject = (value: string) =>
  ({
    "data-testid": value,
  } as PrimereactPassThroughCastT);

export const validateEmailAddress = (emailAddress: string) => {
  const emailAddressLowercase = emailAddress.toLowerCase();
  if (
    emailAddressLowercase.indexOf("@gmail.com") > -1 ||
    emailAddressLowercase.indexOf("@yahoo.com") > -1 ||
    emailAddressLowercase.indexOf("@hotmail.com") > -1 ||
    emailAddressLowercase.indexOf("@aol.com") > -1 ||
    emailAddressLowercase.indexOf("@outlook.com") > -1 ||
    emailAddressLowercase.indexOf("@msn.com") > -1
  ) {
    return "This service is for enterprise businesses, please enter your work email address.";
  }
  return true;
};

export const distinctArrayValues = (
  value: any,
  index: number,
  array: any[]
) => {
  return array.indexOf(value) === index;
};

function debounce(callback: (...args: any[]) => void, wait: number) {
  let timeoutId: number | undefined;
  return (...args: any[]) => {
    window.clearTimeout(timeoutId);
    timeoutId = window.setTimeout(() => {
      callback(...args);
    }, wait);
  };
}
