import React from "react";
import heroImage from "shared/images/hero-1.png";
import { useLocation } from "react-router-dom";
import "pages/account-created/components/account-created.css";

export const AccountCreated = (): JSX.Element => {
  const isPilot = useLocation().state ? useLocation().state.isPilot : false;

  return (
    <div className="row">
      <div className="col-md-6">
        <div className="shadow p-3 mb-4 bg-white rounded">
          <div className="thank-you-container">
            <h1 className="text-center">Thank You!</h1>
            {isPilot && (
              <p className="text-center max-w-30rem m-auto mt-3">
                Keep an eye on your email for account verification.
                <br /> Once verified, you will select the languages for your
                trial and have immediate access to a unique API key.
              </p>
            )}
          </div>
        </div>
      </div>
      <div className="col-md-6">
        <div className="ps-5 pt-5">
          <img
            src={heroImage}
            className="img-fluid"
            alt=""
            width="684"
            height="554"
          />
        </div>
      </div>
    </div>
  );
};
