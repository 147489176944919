import { useEffect, useState } from "react";

const useAuthState = () => {
  const [state, setState] = useState({
    authenticated: false,
  });

  useEffect(() => {
    setState(() => {
      const authCookieVal = document.cookie
        .split("; ")
        .find((cookie) => cookie.startsWith("lio821e="))
        ?.split("=")[1];

      return {
        authenticated: !!parseInt(authCookieVal || "0"),
      };
    });
  }, [document.cookie]);

  return state;
};

export default useAuthState;
