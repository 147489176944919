export enum PermissionGroup {
  USER = "user",
  GLOSSARY = "glossary",
  PROJECT = "project",
  OPTIMIZATION = "optimization",
  MT_PORTAL = "mt-portal",
  SETTINGS = "settings",
  API_KEY = "api-key",
}

export enum Permission {
  VIEW = "view",
  ADD = "add",
  EDIT = "edit",
  DELETE = "delete",
  USE = "use",
}
