import React from "react";
import "./style.css";
import useAuthState from "../../shared/hooks/useAuthState";
import { Link } from "react-router-dom";
import { routerPaths } from "../../router/paths";

const PageNotFound = () => {
  const { authenticated } = useAuthState();

  return (
    <>
      <h1 className="not-found-header text-center mt-8 mb-0 line-height-1">
        404
      </h1>
      <h2 className="not-found-header-text text-center mb-3">Page not found</h2>
      <p className="text-center mb-0">
        We can&apos;t find the page you are looking for.
      </p>
      <p className="text-center">
        Please navigate to{" "}
        <Link
          to={
            authenticated
              ? routerPaths.authenticated.accountPage
              : routerPaths.unauthenticated.index
          }
        >
          home page
        </Link>{" "}
        to get back on track.
      </p>
    </>
  );
};

export default PageNotFound;
