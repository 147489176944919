import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column, ColumnSortEvent } from "primereact/column";
import { useUsersStore } from "../../../../shared/store/users";
import { User } from "../../../../shared/types/User";
import AddUser from "./AddUser";
import {
  distinctArrayValues,
  getDataTestIdPtObject,
} from "../../../../shared/functions/utils";
import DisableUser from "./DisableUser";
import Header from "./Header";
import EditUser from "./EditUser";
import { Dialog } from "primereact/dialog";
import { useConsumerStore } from "shared/store/consumer";

const getTimeSortFn =
  (propName: "createdAt" | "updatedAt") => (e: ColumnSortEvent) => {
    (e.data as User[]).sort((a, b) => {
      const aValue = a[propName].value;
      const bValue = b[propName].value;
      if (e.order == 1) {
        return aValue - bValue;
      } else {
        return bValue - aValue;
      }
    });
    return e.data;
  };

const columnConfig = [
  { header: "First Name", field: "firstName" },
  { header: "Last Name", field: "lastName" },
  { header: "Email", field: "email" },
  { header: "Team", field: "team" },
  { header: "Role", field: "role", fieldPath: "role.label" },
  { header: "Status", field: "status" },
  {
    header: "Date Created",
    field: "createdAt",
    fieldPath: "createdAt.label",
    sortFn: getTimeSortFn("createdAt"),
  },
  {
    header: "Last Edited",
    field: "updatedAt",
    fieldPath: "updatedAt.label",
    sortFn: getTimeSortFn("updatedAt"),
  },
];

const Users = () => {
  const { activeUsers, fetchUsers } = useUsersStore();
  const [isUnableToProcessDialogVisible, setIsUnableToProcessDialogVisible] =
    useState(false);
  const [
    isResendInviteConfirmationDialogVisible,
    setIsResendInviteConfirmationDialogVisible,
  ] = useState(false);
  const [inviteResentToEmails, setInviteResentToEmails] = useState<string[]>(
    []
  );

  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);

  const [isAddUserOpen, setIsAddUserOpen] = useState(false);
  const [isEditUserOpen, setIsEditUserOpen] = useState(false);
  const [showDisableConfirmationMessage, setShowDisableConfirmationMessage] =
    useState(false);

  useEffect(() => {
    fetchUsers();
  }, []);

  const userTeams = activeUsers()
    .map((usr) => usr.team)
    .filter((team) => team)
    .filter(distinctArrayValues) as string[];

  const showUnableToProcessDialog = () => {
    setIsUnableToProcessDialogVisible(true);
  };

  const hideResendInviteConfirmationDialog = () => {
    setIsResendInviteConfirmationDialogVisible(false);
    setInviteResentToEmails([]);
  };

  const { currentConsumer } = useConsumerStore();

  return (
    <>
      <Dialog
        header="Unable to process"
        visible={isUnableToProcessDialogVisible}
        onHide={() => setIsUnableToProcessDialogVisible(false)}
      >
        <p className="text-secondary">
          There needs to be at least one active Admin user per group.
        </p>
      </Dialog>
      <Dialog
        style={{ maxWidth: 700 }}
        header="Invitation resent"
        visible={isResendInviteConfirmationDialogVisible}
        onHide={hideResendInviteConfirmationDialog}
      >
        <p className="text-secondary">
          An email invitation has been resent to the following user
          {inviteResentToEmails.length > 1 ? "s" : ""}:
        </p>
        <p
          {...getDataTestIdPtObject("inviteResentConfirmationEmailList")}
          className="text-secondary"
        >
          {inviteResentToEmails.join(", ")}
        </p>
      </Dialog>
      <Header
        selectedUsers={selectedUsers}
        setSelectedUsers={setSelectedUsers}
        setShowDisableConfirmationMessage={setShowDisableConfirmationMessage}
        setIsAddUserOpen={setIsAddUserOpen}
        setIsEditUserOpen={setIsEditUserOpen}
        setInviteResentToEmails={setInviteResentToEmails}
        setIsInviteResentConfirmationVisible={
          setIsResendInviteConfirmationDialogVisible
        }
      />
      <AddUser
        isAddUserOpen={isAddUserOpen}
        setIsAddUserOpen={setIsAddUserOpen}
        teams={userTeams}
      />
      <DisableUser
        showDisableConfirmationMessage={showDisableConfirmationMessage}
        setShowDisableConfirmationMessage={setShowDisableConfirmationMessage}
        selectedUserIds={selectedUsers.map((i) => i.id)}
        onDisable={() => setSelectedUsers([])}
        showUnableToProcessDialog={showUnableToProcessDialog}
      />
      <EditUser
        teams={userTeams}
        isEditUserOpen={isEditUserOpen}
        setIsEditUserOpen={setIsEditUserOpen}
        selectedUsers={selectedUsers}
        setSelectedUsers={setSelectedUsers}
        showUnableToProcessDialog={showUnableToProcessDialog}
      />
      <DataTable
        value={activeUsers()}
        size="small"
        sortField="createdAt.value"
        selectionMode={currentConsumer.isSsoUser ? null : "checkbox"}
        selection={selectedUsers}
        onSelectionChange={(e: any) => {
          setSelectedUsers(e.value);
        }}
        sortOrder={-1}
        pt={{
          headerRow: getDataTestIdPtObject("usersTableHeaderRow"),
        }}
      >
        <Column
          selectionMode={currentConsumer.isSsoUser ? undefined : "multiple"}
          pt={{
            checkbox: getDataTestIdPtObject("rowSelectCheckbox"),
          }}
        />
        {columnConfig.map((columnData) => (
          <Column
            key={columnData.field}
            header={columnData.header}
            field={columnData.fieldPath || columnData.field}
            sortable
            sortFunction={columnData.sortFn}
            pt={{
              headerTitle: getDataTestIdPtObject(
                columnData.field + "ColumnTitle"
              ),
              bodyCell: getDataTestIdPtObject(columnData.field + "Cell"),
            }}
          />
        ))}
      </DataTable>
    </>
  );
};

export default Users;
