import { StateSliceCreator } from "../../utils/types";
import { ConsumerAPI } from "../../../api/api-golinguist/consumerAPI";

export type apiKeySliceT = {
  isLoading: boolean;
  apiKey: string;
  allowApiKeyCycle: boolean;
  resetApiKeyCycle: () => void;
  fetchApiKey: () => void;
  cycleApiKey: () => void;
};

export const createApiKeySlice: StateSliceCreator<apiKeySliceT> = (
  set,
  get
) => ({
  isLoading: false,
  apiKey: "",
  allowApiKeyCycle: true,
  cycleApiKey: async () => {
    if (!get().allowApiKeyCycle) return;

    const response = await ConsumerAPI.cycleApiKey();
    set(() => ({
      apiKey: response?.apiKey,
      isLoading: false,
      allowApiKeyCycle: false,
    }));
  },
  resetApiKeyCycle: () => {
    set(() => ({ allowApiKeyCycle: true }));
  },
  fetchApiKey: async () => {
    // do not fetch more than once
    if (get().apiKey.length > 0) return;

    set(() => ({
      isLoading: true,
    }));

    const response = await ConsumerAPI.getApiKey();
    set(() => ({
      apiKey: response?.apiKey,
      isLoading: false,
    }));
  },
});
