import { linguistnowAPI } from "./index";
import {
  DBUser,
  Role,
  UserCreatePayload,
  UserUpdatePayload,
} from "../../types/User";
import {
  PersonalInformation,
  PersonalInformationBase,
} from "../../types/PersonalInformation";

export const UserAPI = {
  getUsers: async () => {
    return linguistnowAPI<DBUser[]>({
      url: "linguistnow/user/findAll",
    });
  },
  getRoles: async () => {
    return linguistnowAPI<Role[]>({
      url: "linguistnow/user/role",
    });
  },
  createUser: async (data: UserCreatePayload) => {
    return linguistnowAPI<{ success: boolean; user: DBUser | null }>({
      url: "linguistnow/user/create",
      method: "POST",
      data,
    });
  },
  updateUser: async (data: UserUpdatePayload) => {
    return linguistnowAPI<{ success: boolean; user: DBUser | null }>({
      url: "linguistnow/user/update",
      method: "POST",
      data,
    });
  },
  disableUser: async (data: number[]) => {
    return linguistnowAPI({
      url: "linguistnow/user/disable",
      method: "POST",
      data,
    });
  },
  resendEmailInvite: async (data: number[]) => {
    return linguistnowAPI({
      url: "linguistnow/user/resendEmailInvite",
      method: "POST",
      data,
    });
  },
  getPersonalInfo: async () => {
    return linguistnowAPI<PersonalInformation>({
      url: `linguistnow/user/personalInfo`,
    });
  },
  updatePersonalInfo: async (data: PersonalInformationBase) => {
    return linguistnowAPI({
      url: `linguistnow/user/personalInfo`,
      method: "PUT",
      data,
    });
  },
};
