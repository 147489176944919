import React from "react";
import { Link, useLocation } from "react-router-dom";

type PropsT = {
  linkText: string;
  linkTo: string;
};

const NavLink = (props: PropsT) => {
  const { pathname } = useLocation();

  return (
    <Link
      to={props.linkTo}
      className={`nav-link${pathname.includes(props.linkTo) ? " active" : ""}`}
    >
      <strong>{props.linkText}</strong>
    </Link>
  );
};

export default NavLink;
