import { create } from "zustand";
import { devtools } from "zustand/middleware";
import {
  consumerHasLocalesSliceT,
  createConsumerHasLocalesSlice,
} from "../consumer/slices/consumerHasLocalesSlice";

type MTPortalStore = {
  isLoading: boolean;
} & consumerHasLocalesSliceT;

export const useMTPortalStore = create<MTPortalStore>()(
  devtools((set, get) => ({
    ...createConsumerHasLocalesSlice(set, get),
    isLoading: false,
  }))
);
