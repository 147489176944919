import { Nullable } from "primereact/ts-helpers";
import { ConsumerTrial } from "../../../types/consumer/ConsumerTrial";
import { StateSliceCreator } from "../../utils/types";
import {
  createCurrentConsumerSlice,
  currentConsumerSliceT,
} from "./currentConsumerSlice";
import UserManagementAPI from "../../../api/api-phase-1/userManagementAPI";

export type trialSliceT = {
  consumerTrial: Nullable<ConsumerTrial>;
  fetchConsumerTrial: () => void;
} & currentConsumerSliceT;

export const createTrialSlice: StateSliceCreator<trialSliceT> = (set, get) => ({
  ...createCurrentConsumerSlice(set, get),
  consumerTrial: null,
  fetchConsumerTrial: async () => {
    // do not fetch more than once
    if (get().consumerTrial) return;

    if (get().currentConsumer.encryptedSelectedConsumerId.length === 0) {
      await get().fetchCurrentConsumer();
    }
    const response = await UserManagementAPI.getConsumerTrial(
      get().currentConsumer.encryptedSelectedConsumerId
    );
    set((state) => {
      if (response) {
        return {
          consumerTrial: {
            isTrial: response.isTrial,
            totalAvailableWords: response.totalAvailableWords,
            totalWordsConsumed: response.totalWordsConsumed,
            startDate: response.startDate && new Date(response.startDate),
            endDate: response.endDate && new Date(response.endDate),
          },
        };
      }
      return state;
    });
  },
});
