import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { Locale } from "../types/Locale";
import LookupAPI from "../api/api-phase-1/lookupAPI";

interface LocalesStore {
  locales: Locale[];
  fetchLocales: () => void;
  isLoading: boolean;
}

export const useLocalesStore = create<LocalesStore>()(
  devtools((set, get) => ({
    locales: [],
    isLoading: false,
    fetchLocales: async () => {
      if (!get().locales.length) {
        set(() => ({ isLoading: true }));

        try {
          const locales = await LookupAPI.getLocales();
          set(() => ({
            locales: locales.sort(
              ({ mtDisplayName: a }, { mtDisplayName: b }) => {
                if (a && b) {
                  return a.localeCompare(b);
                }
                return 0;
              }
            ),
          }));
        } finally {
          set(() => ({ isLoading: false }));
        }
      }
    },
  }))
);
