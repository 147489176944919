import React from "react";

interface OuterContainerProps {
  child?: React.ReactNode;
}

export const OuterContainer: React.FC<OuterContainerProps> = (props) => {
  const { child } = props;
  return (
    <div className="container">
      <div className="row">
        <div className="col">{child}</div>
      </div>
    </div>
  );
};
