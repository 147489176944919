import { apiPhase1 } from "./index";
import { WebUser } from "../../types/WebUser";

export const UserAPI = {
  createWebUser: async (user: WebUser) => {
    return apiPhase1<{
      id: string;
      status: string;
    }>({
      url: "user/users/",
      method: "POST",
      data: user,
    });
  },
  resetUserEmail: async (email: string) => {
    return apiPhase1<any>({
      url: "user/users/resetpassword",
      method: "POST",
      params: {
        email,
      },
      // if data is not specified for POST request, axios errors out with CORS
      // to me, it looks like axios does not supply body to POST request if data is not specified
      // and BE expects POST request to have body, and returns no Access-Control-Allow-Origin header which triggers CORS error
      // OR maybe it's just Content-Type header that is sent when 'data=null', and not sent when 'data' is omitted from config
      data: null,
    });
  },
};
