import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { createApiKeySlice, apiKeySliceT } from "./slices/apiKeySlice";
import { createTrialSlice, trialSliceT } from "./slices/trialSlice";
import {
  createCurrentConsumerSlice,
  currentConsumerSliceT,
} from "./slices/currentConsumerSlice";
import {
  consumerHasLocalesSliceT,
  createConsumerHasLocalesSlice,
} from "./slices/consumerHasLocalesSlice";
import {
  ConsumerToggleSliceT,
  createConsumerToggleSlice,
} from "./slices/consumerToggleSlice";

type ConsumerStore = {
  isLoading: boolean;
} & currentConsumerSliceT &
  trialSliceT &
  apiKeySliceT &
  consumerHasLocalesSliceT &
  ConsumerToggleSliceT;

export const useConsumerStore = create<ConsumerStore>()(
  devtools((set, get) => ({
    ...createCurrentConsumerSlice(set, get),
    ...createTrialSlice(set, get),
    ...createApiKeySlice(set, get),
    ...createConsumerHasLocalesSlice(set, get),
    ...createConsumerToggleSlice(set, get),
    isLoading: false,
  }))
);
