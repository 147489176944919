import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { ServerError } from "../../types/ServerError";

type AppErrorsStore = {
  hasServerError: boolean;
  serverError?: ServerError;
  httpStatus?: number;
  setServerError: (e?: ServerError, httpStatus?: number) => void;
  resetServerError: () => void;
  hasRuntimeError: boolean;
  setRuntimeError: () => void;
  isSignupError?: boolean;
  setIsSignupError: (value: boolean) => void;
};

const useAppErrorsState = create<AppErrorsStore>()(
  devtools((set) => ({
    hasServerError: false,
    serverError: undefined,
    httpStatus: undefined,
    setServerError: (serverError, httpStatus) => {
      set({ serverError, httpStatus, hasServerError: true });
    },
    resetServerError: () => {
      set({
        serverError: undefined,
        httpStatus: undefined,
        hasServerError: false,
      });
    },
    hasRuntimeError: false,
    setRuntimeError: () => {
      set({ hasRuntimeError: true });
    },
    setIsSignupError: (isSignupError) => {
      set({ isSignupError });
    },
  }))
);

export default useAppErrorsState;
