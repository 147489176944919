import React from "react";
import { ParsingRule } from "shared/types/ParsingRule";
import { Button } from "primereact/button";
import "pages/settings/components/glossary/glossary.css";
import { TableContents } from "./TableContents";
import { GlossaryEditConfirmation } from "./GlossaryEditConfirmation";
import { DataTableEditingRows } from "primereact/datatable";
import { STATUS } from "../../utils/GlossaryTableConstants";
import { getDataTestIdPtObject } from "../../../../shared/functions/utils";
import {
  Permission,
  PermissionGroup,
} from "../../../../shared/types/UserPermission";
import { useConsumerStore } from "../../../../shared/store/consumer";

interface GlossaryDataTableProps {
  displayParsingRules: ParsingRule[];
  modifiedParsingRules: ParsingRule[];
  setDisplayParsingRules: React.Dispatch<React.SetStateAction<ParsingRule[]>>;
  setModifiedParsingRules: React.Dispatch<React.SetStateAction<ParsingRule[]>>;
  addNewRow: () => void;
  showEditConfirmation: boolean;
  setShowEditConfirmation: React.Dispatch<React.SetStateAction<boolean>>;
  editingRows: DataTableEditingRows;
  setEditingRows: React.Dispatch<React.SetStateAction<DataTableEditingRows>>;
  selectedItems: ParsingRule[];
  setSelectedItems: React.Dispatch<React.SetStateAction<ParsingRule[]>>;
  dataTableSelection?: ParsingRule[];
  setDataTableSelection?: React.Dispatch<React.SetStateAction<ParsingRule[]>>;
  deleteParsingRules: () => void;
  revertParsingRules: () => void;
}

export const GlossaryDataTable = (props: GlossaryDataTableProps) => {
  const [isPublished, setIsPublished] = React.useState<boolean>(false);
  const { hasPermission } = useConsumerStore();
  const isEditingRows = React.useMemo(() => {
    return !!Object.entries(props.editingRows).length;
  }, [props.editingRows]);

  const isActiveConfirmBtn = React.useMemo(() => {
    return (
      props.modifiedParsingRules.length &&
      !props.showEditConfirmation &&
      !isEditingRows
    );
  }, [props.modifiedParsingRules, props.showEditConfirmation, isEditingRows]);

  const allSelectedRowsDeleted = React.useMemo(() => {
    return !!(
      (props.selectedItems as ParsingRule[]).length &&
      (props.selectedItems as ParsingRule[]).every(
        (item) => item.status === STATUS.DELETE
      )
    );
  }, [props.selectedItems]);

  const numSelectedItems = props.selectedItems.length;

  const alertUser = (e: BeforeUnloadEvent) => {
    if (props.modifiedParsingRules.length > 0 && !isPublished) {
      e.preventDefault();
      e.returnValue =
        'You are navigating away from unpublished changes. Click "Leave page" to continue and lose any unsaved changes. To return, press "Stay on page"';
    }
    return;
  };

  React.useEffect(() => {
    window.addEventListener("beforeunload", alertUser);
    return () => {
      window.removeEventListener("beforeunload", alertUser);
    };
  }, [props.modifiedParsingRules, isPublished]);

  return (
    <>
      {props.showEditConfirmation ? (
        <GlossaryEditConfirmation
          displayParsingRules={props.displayParsingRules}
          setDisplayParsingRules={props.setDisplayParsingRules}
          modifiedParsingRules={props.modifiedParsingRules}
          setModifiedParsingRules={props.setModifiedParsingRules}
          showEditConfirmation={true}
          setShowEditConfirmation={props.setShowEditConfirmation}
          isPublished={isPublished}
          setIsPublished={setIsPublished}
        />
      ) : (
        <>
          <TableContents
            displayParsingRules={props.displayParsingRules}
            setDisplayParsingRules={props.setDisplayParsingRules}
            modifiedParsingRules={props.modifiedParsingRules}
            setModifiedParsingRules={props.setModifiedParsingRules}
            showEditConfirmation={props.showEditConfirmation}
            addNewRow={props.addNewRow}
            editingRows={props.editingRows}
            setEditingRows={props.setEditingRows}
            selectedItems={props.selectedItems}
            setSelectedItems={props.setSelectedItems}
            dataTableSelection={props.dataTableSelection}
            setDataTableSelection={props.setDataTableSelection}
          />
          <div className="my-3 mx-2 d-flex justify-content-between">
            <div>
              {hasPermission(PermissionGroup.GLOSSARY, Permission.DELETE) && (
                <>
                  <Button
                    onClick={props.deleteParsingRules}
                    label={`Delete ${
                      numSelectedItems ? numSelectedItems + " " : ""
                    }items`}
                    disabled={
                      numSelectedItems === 0 ||
                      isEditingRows ||
                      allSelectedRowsDeleted
                    }
                    className="mr-2"
                    text
                    severity="secondary"
                    pt={{ root: getDataTestIdPtObject("deleteItemsBtn") }}
                  />
                  <Button
                    onClick={props.revertParsingRules}
                    label="Revert items"
                    disabled={!allSelectedRowsDeleted}
                    text
                    severity="secondary"
                    pt={{ root: getDataTestIdPtObject("revertDeleteItemsBtn") }}
                  />
                </>
              )}
            </div>
            {(hasPermission(PermissionGroup.GLOSSARY, Permission.EDIT) ||
              hasPermission(PermissionGroup.GLOSSARY, Permission.ADD)) && (
              <Button
                disabled={!isActiveConfirmBtn}
                label="Confirm Changes"
                onClick={() => props.setShowEditConfirmation((prev) => !prev)}
                pt={{ root: getDataTestIdPtObject("confirmChangesBtn") }}
              />
            )}
          </div>
        </>
      )}
    </>
  );
};
