import { apiPhase1 } from "./index";
import { ConsumerTrial } from "../../types/consumer/ConsumerTrial";
import { ConsumerHasIntegrationTypesIn } from "../../types/CreateConsumerIntegrationTypesData";
import { CreateConsumerData } from "../../types/ConsumerData";

export const UserManagementAPI = {
  createConsumer: async (consumer: CreateConsumerData) => {
    return apiPhase1<{
      id: string;
      status: string;
    }>({
      url: "ums/consumers/",
      method: "POST",
      data: consumer,
    });
  },
  getConsumerTrial: async (consumerId: string) => {
    return apiPhase1<ConsumerTrial>({
      url: `ums/consumers/${consumerId}/trial`,
    });
  },
  createConsumerIntegrationTypes: async (
    consumerId: string,
    data: ConsumerHasIntegrationTypesIn
  ) => {
    // type 'any' because we don't currently process response where the call is made
    return apiPhase1<any>({
      url: `ums/consumers/${consumerId}/integrationtypes/`,
      method: "POST",
      data,
    });
  },
  createVendorsAutoAssigns: async (consumerId: string) => {
    // type 'any' because we don't currently process response where the call is made
    return apiPhase1<any>({
      url: `ums/consumers/${consumerId}/vendorautoassigns/`,
      method: "POST",
      // if data is not specified for POST request, axios errors out with CORS
      data: null,
    });
  },
};

export default UserManagementAPI;
