import React, { useState } from "react";
import { getGoLinguistDomain } from "../../../api/utils";
import { useConsumerStore } from "shared/store/consumer";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

const AuthButton = (props: { isAuthenticatedPath: boolean }) => {
  const { currentConsumer } = useConsumerStore();
  const [showSsoLogoutModal, setShowSsoLogoutModal] = useState(false);

  const handleLogout = () => {
    if (currentConsumer.isSsoUser) {
      setShowSsoLogoutModal(true);
    } else {
      window.location.href = "/linguistnow/launch/logout";
    }
  };

  const handleConfirmLogout = () => {
    setShowSsoLogoutModal(false);
    window.location.href = "/linguistnow/launch/logout";
  };

  return (
    <>
      {props.isAuthenticatedPath ? (
        <>
          <Button
            id="logout-link"
            className="fw-bold py-2 px-4 rounded-pill border-primary border border-2 white-space-nowrap"
            link
            onClick={handleLogout}
          >
            Sign Out
          </Button>
          <Dialog
            header="Note to SSO users"
            visible={showSsoLogoutModal}
            onHide={() => setShowSsoLogoutModal(false)}
            position="top-right"
            style={{ width: "50vw" }}
            footer={
              <div>
                <Button
                  label="Ok, logout."
                  onClick={handleConfirmLogout}
                ></Button>
              </div>
            }
          >
            <p>
              This will log you out of your Language I/O account. However, you
              are still logged in to your Single Sign-On (SSO) account. To
              switch users in Language I/O, please log out of your SSO account
              first (directly on the SSO website), and then log in to Language
              I/O again with the desired user credentials.
            </p>
          </Dialog>
        </>
      ) : (
        <a
          id="login-link"
          className="fw-bold py-2 px-4 rounded-pill border-primary border border-2"
          /* For some reason we have to specify absolute url for login "linguistnow/login",
           * but not for logout - "/linguistnow/launch/logout".
           * Assumption is that it's handled differently on back-end.
           *  */
          href={getGoLinguistDomain() + "linguistnow/login"}
        >
          Sign In
        </a>
      )}
    </>
  );
};

export default AuthButton;
