import React from "react";
import useAppErrorsState from "../store/app-errors";
import { getGoLinguistDomain } from "../api/utils";

export const OverlayError = () => {
  const { hasServerError, hasRuntimeError, serverError, isSignupError } =
    useAppErrorsState();

  const errorMessage =
    serverError?.userMessage ||
    "An error has occurred, please refresh and try again.";

  const signupError = (
    <>
      <div>An error occurred.</div>
      <div>
        If you have previously created a Language I/O account, please attempt to
        recover your account password by clicking on “Forgot Password”{" "}
        <a href={getGoLinguistDomain() + "linguistnow/login"}>at this link</a>.
      </div>
    </>
  );

  return hasServerError || hasRuntimeError || isSignupError ? (
    <div id="error-overlay">
      <div className="row pt-5 justify-content-center">
        <div className="col-6 mt-5">
          <div className="text-center alert alert-danger mt-5" role="alert">
            {isSignupError ? signupError : errorMessage}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};
