import React from "react";
import { TabPanel, TabView } from "primereact/tabview";
import PersonalInformation from "./PersonalInformation";
import Plan from "./Plan";
import { useConsumerStore } from "../../../shared/store/consumer";
import "./style.css";
import APIKey from "./apiKey";
import Users from "./Users";
import {
  Permission,
  PermissionGroup,
} from "../../../shared/types/UserPermission";
import TabViewThatWorksWithPermissions from "../../../shared/components/TabViewThatWorksWithPermissions";
import { Card } from "primereact/card";

const AccountHome = () => {
  const { currentConsumer, consumerTrial, hasPermission, fetchConsumerTrial } =
    useConsumerStore();

  React.useEffect(() => {
    fetchConsumerTrial();
  }, []);

  return (
    <div className="container">
      <div className="row justify-content-center wide-tabview">
        {currentConsumer.encryptedSelectedConsumerId && (
          <TabViewThatWorksWithPermissions className="wide-tabview">
            {hasPermission(PermissionGroup.USER, Permission.VIEW) && (
              <TabPanel className="wide-tabpanel" header="Users">
                <Users />
              </TabPanel>
            )}
            <TabPanel header="Personal Information">
              {currentConsumer.isSsoUser ? (
                <Card className="mt-7 border">
                  <p className="my-8 text-lg">
                    Account details are managed by your Single Sign-On provider.
                  </p>
                </Card>
              ) : (
                <PersonalInformation />
              )}
            </TabPanel>
            {consumerTrial?.isTrial && (
              <TabPanel header="Plan">
                <Plan />
              </TabPanel>
            )}
            {hasPermission(PermissionGroup.API_KEY, Permission.VIEW) && (
              <TabPanel header="API Key">
                <APIKey />
              </TabPanel>
            )}
          </TabViewThatWorksWithPermissions>
        )}
      </div>
    </div>
  );
};

export default AccountHome;
