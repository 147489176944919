import React from "react";
import { Link } from "react-router-dom";
import logo from "../../images/logo.png";
import "./style.css";
import AuthButton from "./components/AuthButton";
import { routerPaths } from "../../../router/paths";
import Nav from "./components/Nav";
import useAuthState from "../../hooks/useAuthState";
import ConsumerToggle from "./components/ConsumerToggle";

export const Header = (): JSX.Element => {
  const { authenticated } = useAuthState();

  const logoLinkTo =
    (authenticated && routerPaths.authenticated.accountPage) ||
    routerPaths.unauthenticated.index;

  return (
    <div className="fixed-top">
      <div className="navigation navbar navbar-expand-sm navbar-dark bg-white ps-5 pe-5 pt-0 pb-0 d-flex justify-content-between">
        <div>
          <Link to={logoLinkTo}>
            <img
              id="logo"
              src={logo}
              alt="logo"
              className="img-fluid"
              width="250"
              height="51"
            />
          </Link>
        </div>

        {authenticated && <Nav />}
        <div className="flex align-items-center">
          <a
            className="nav-link fw-bold white-space-nowrap px-2 mr-3"
            href="https://share.hsforms.com/1JD7KZom9RJO-H2Mbadh30g2dxtp"
            target="_blank"
            rel="noreferrer"
          >
            Contact Us
          </a>
          <AuthButton isAuthenticatedPath={authenticated} />
        </div>
      </div>
      {authenticated && (
        <div className="w-100">
          <ConsumerToggle />
        </div>
      )}
    </div>
  );
};
