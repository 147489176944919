import React from "react";
import { HashRouter as Router } from "react-router-dom";
import ErrorBoundary from "shared/components/ErrorBoundary";
import { OverlayError } from "shared/components/OverlayError";
import "shared/css/main.css";
import AppRouter from "./router";
import Footer from "./shared/components/Footer";
import { Header } from "./shared/components/Header";

const App = (): JSX.Element => {
  return (
    <ErrorBoundary>
      <Router>
        <div id="page-container">
          <div id="content-wrap">
            <Header />
            <div id="lio-wrapper">
              <AppRouter />
            </div>
          </div>
          <Footer />
          <OverlayError></OverlayError>
          <div id="loading-overlay">
            <div
              id="loading-overlay-text"
              className="flex justify-content-center align-items-center h-100"
            >
              <div
                id="spinner-border"
                className="spinner-border"
                role="status"
              ></div>
            </div>
          </div>
        </div>
      </Router>
    </ErrorBoundary>
  );
};
export default App;
