import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { Nullable } from "primereact/ts-helpers";
import {
  PersonalInformation,
  PersonalInformationBase,
} from "../types/PersonalInformation";
import {
  UserAPI as linguistNowUserAPI,
  UserAPI,
} from "../api/api-golinguist/userAPI";

interface PersonalInfoStoreT {
  personalInfo: Nullable<PersonalInformation>;
  fetchPersonalInfo: () => Promise<any>;
  updatePersonalInfo: (data: PersonalInformationBase) => Promise<any>;
}

export const usePersonalInfoStore = create<PersonalInfoStoreT>()(
  devtools((set, get) => ({
    personalInfo: null,
    fetchPersonalInfo: async () => {
      const resp = await UserAPI.getPersonalInfo();
      set({ personalInfo: resp });
    },
    updatePersonalInfo: async (data: PersonalInformationBase) => {
      await linguistNowUserAPI.updatePersonalInfo(data);
      const newPersonalInfo = {
        email: get().personalInfo?.email || "",
        firstName: data.firstName,
        lastName: data.lastName,
      };
      set({ personalInfo: newPersonalInfo });
    },
  }))
);
