import React, { useEffect } from "react";
import { Card } from "primereact/card";
import { useConsumerStore } from "../../../shared/store/consumer";
import { FieldCategoryHeader, FieldHeader, TabHeader } from "./shared/Headers";

const Plan = () => {
  const [consumerTrial, fetchConsumerTrial] = useConsumerStore((state) => [
    state.consumerTrial,
    state.fetchConsumerTrial,
  ]);

  useEffect(() => {
    fetchConsumerTrial();
  }, []);

  const wordsRemainingDisplayText = new Intl.NumberFormat().format(
    (consumerTrial?.totalAvailableWords || 0) -
      (consumerTrial?.totalWordsConsumed || 0)
  );
  const totalWordsDisplayText = new Intl.NumberFormat().format(
    consumerTrial?.totalAvailableWords || 0
  );

  const startDateDisplayText = consumerTrial?.startDate
    ? new Intl.DateTimeFormat().format(consumerTrial?.startDate)
    : null;
  const endDateDisplayText = consumerTrial?.endDate
    ? new Intl.DateTimeFormat().format(consumerTrial?.endDate)
    : null;

  let totalTrialDays = 0;
  let trialDaysRemaining = 0;
  if (consumerTrial?.startDate && consumerTrial.endDate) {
    // milliseconds in a day: ms * sec * min * hour
    const MS_PER_DAY = 1000 * 60 * 60 * 24;

    const trialEndInMs = consumerTrial.endDate.getTime();
    const trialLengthInMs = trialEndInMs - consumerTrial.startDate.getTime();

    totalTrialDays = Math.ceil(trialLengthInMs / MS_PER_DAY);

    const currentTimeInMs = new Date().getTime();
    const trialTimeRemainingInMs = trialEndInMs - currentTimeInMs;

    trialDaysRemaining = Math.max(
      0,
      Math.ceil(trialTimeRemainingInMs / MS_PER_DAY)
    );
  }

  return (
    <Card className="mt-7 border">
      <TabHeader>Language I/O Free</TabHeader>
      <FieldCategoryHeader>Plan information</FieldCategoryHeader>
      <FieldHeader className="mt-4">Usage period</FieldHeader>
      <p className="mt-1 mb-4m text-sm text-gray-600">
        {startDateDisplayText || "not available"} -{" "}
        {endDateDisplayText || "not available"}
      </p>
      <p className="m-0 mb-1 text-sm text-gray-600">
        {trialDaysRemaining} / {totalTrialDays} Free days remaining
      </p>
      <p className="m-0 text-sm text-gray-600">
        {wordsRemainingDisplayText} / {totalWordsDisplayText} Words remaining
      </p>
    </Card>
  );
};

export default Plan;
