import React, { ReactNode, useEffect, useMemo, useState } from "react";
import Item from "./Item";
import { CheckboxChangeEvent } from "primereact/checkbox";

export type ItemT = {
  id: number;
  name: string;
  checked: boolean;
  disabled?: boolean;
};

type PropsT = {
  items: ItemT[];
  setItems: React.Dispatch<React.SetStateAction<ItemT[]>>;
  onChangeCb: (item: ItemT | ItemT[]) => void;
  allDisabled?: boolean;
  filterValue?: string;
  headerItemChildren?: ReactNode;
  itemChildren?: ReactNode | ((id: number) => ReactNode);
};

const CheckboxMultiSelect = (props: PropsT) => {
  const [selectAll, setSelectAll] = useState({
    id: -1,
    name: "Select All",
    checked: false,
  });

  useEffect(() => {
    const allItemsSelected =
      props.items.length > 0 && props.items.every((item) => item.checked);

    setSelectAll((prevState) => {
      return {
        ...prevState,
        checked: allItemsSelected,
      };
    });
  }, [props.items]);

  const onChange = (id: number | string, e: CheckboxChangeEvent) => {
    if (id === selectAll.id) {
      const itemChecked = e.target.checked || false;

      setSelectAll((prevState) => {
        return { ...prevState, checked: itemChecked };
      });

      props.setItems((prevState) => {
        const newState = prevState.map((item) => {
          return {
            ...item,
            checked: item.disabled ? true : itemChecked,
          };
        });

        props.onChangeCb(itemChecked ? newState : []);

        return newState;
      });
      return;
    }

    props.setItems((prevState) => {
      const itemIndex = prevState.findIndex((el) => el.id === id);
      const item = prevState[itemIndex];
      const newItem = {
        ...item,
        checked: !item.checked,
      };

      props.onChangeCb(newItem);

      prevState.splice(itemIndex, 1, newItem);

      return [...prevState];
    });
  };

  const displayItems = useMemo(() => {
    return props.items.filter((item) => {
      if (props.filterValue) {
        return item.name
          .toLowerCase()
          .includes(props.filterValue.toLowerCase());
      }
      return true;
    });
  }, [props.filterValue, props.items]);

  return (
    <>
      <div className="sticky z-2 top-0 bg-white">
        <Item
          className="select-all bg-gray-50 rounded-top border"
          item={selectAll}
          onChange={onChange}
          disabledOverride={props.allDisabled || Boolean(props.filterValue)}
        >
          {props.headerItemChildren}
        </Item>
      </div>
      <div className="checkbox-select-items rounded-bottom border border-top-none">
        {displayItems.map((item) => (
          <Item
            key={item.id}
            item={item}
            onChange={onChange}
            disabledOverride={props.allDisabled}
          >
            {/* The props.itemChildren should be a function when we want to pass item.id up to parent components */}
            {/* otherwise it will be used like ReactNode (JSX) */}
            {typeof props.itemChildren === "function"
              ? props.itemChildren(item.id)
              : props.itemChildren}
          </Item>
        ))}
      </div>
    </>
  );
};

export default CheckboxMultiSelect;
