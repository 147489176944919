import { createApiAccessor, getGoLinguistDomain } from "../utils";
import { createAxiosInstance } from "../axiosConfig";

const linguisnowApiInstance = createAxiosInstance({
  baseURL: getGoLinguistDomain(),
  headers: {
    // this signifies to our server-code instance that this request is ajax (https://developer.mozilla.org/en-US/docs/Web/Guide/AJAX)
    // request.
    // Server logic requires this header to correctly handle 'unauthenticated'(code 401) behavior.
    // (server-code - AbstractAuthenticatedController.java - 'isLoggedIn' and 'isAjax' methods)
    "X-Requested-With": "XMLHttpRequest",
  },
});

export const linguistnowAPI = createApiAccessor(linguisnowApiInstance);
