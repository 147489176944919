import { apiPhase1 } from "./index";
import { Locale } from "../../types/Locale";
import { Country } from "../../types/Country";
import { CompanySize } from "../../types/CompanySize";
import { Industry } from "../../types/Industry";
import { IntegrationType } from "../../types/IntegrationType";

export const LookupAPI = {
  getLocales: async () => {
    return apiPhase1<Locale[]>({
      url: "util/lookup/locales",
    });
  },
  getCountries: async () => {
    return apiPhase1<Country[]>({
      url: "util/lookup/countries",
    });
  },
  getCompanySizes: async () => {
    return apiPhase1<CompanySize[]>({
      url: "util/lookup/companysizes",
    });
  },
  getIndustries: async () => {
    return apiPhase1<Industry[]>({
      url: "util/lookup/industries",
    });
  },
  getIntegrationTypes: async () => {
    return apiPhase1<IntegrationType[]>({
      url: "util/lookup/integrationtypes",
    });
  },
};

export default LookupAPI;
