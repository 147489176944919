import React from "react";
import NavLink from "./NavLink";
import { routerPaths } from "../../../../../router/paths";
import NavServerCode from "./NavServerCode";
import { useConsumerStore } from "../../../../store/consumer";
import { Permission, PermissionGroup } from "../../../../types/UserPermission";

const Nav = () => {
  const { hasPermission } = useConsumerStore();
  return (
    <div className="d-flex link-container align-items-center flex-wrap">
      <div className="d-flex flex-grow-1 justify-content-center">
        <NavLink
          linkText="Account"
          linkTo={routerPaths.authenticated.accountPage}
        />
        {hasPermission(PermissionGroup.SETTINGS, Permission.VIEW) && (
          <NavLink
            linkText="Settings"
            linkTo={routerPaths.authenticated.settingsPage}
          />
        )}
      </div>
      <NavServerCode />
    </div>
  );
};

export default Nav;
