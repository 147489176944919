import React, { useState } from "react";
import { useUsersStore } from "../../../../shared/store/users";
import AddAndEditUserModal, { UserFormData } from "./AddAndEditUserModal";
import { User } from "../../../../shared/types/User";
import { Dialog } from "primereact/dialog";
import { ADMIN_ROLE_ID } from "../../../../shared/constants";

type PropsT = {
  teams: string[];
  isEditUserOpen: boolean;
  setIsEditUserOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedUsers: User[];
  setSelectedUsers: React.Dispatch<React.SetStateAction<User[]>>;
  showUnableToProcessDialog: () => void;
};
const EditUser = (props: PropsT) => {
  const { activeUsers, updateUser } = useUsersStore();

  const selectedUser = props.selectedUsers[0];

  const selectedUserIsTheOnlyAdmin =
    selectedUser &&
    selectedUser.role.id === ADMIN_ROLE_ID &&
    activeUsers().every(
      (user) => user.role.id !== ADMIN_ROLE_ID || user.id === selectedUser.id
    );

  const onSubmit = ({ firstName, lastName, team, role }: UserFormData) => {
    if (selectedUserIsTheOnlyAdmin && role.id !== ADMIN_ROLE_ID) {
      // We don't want a user to change their own role from Admin to non-admin if they are the only admin for the consumer
      props.showUnableToProcessDialog();
      props.setSelectedUsers([]);
      return;
    }

    const updateUserPayload = {
      firstName,
      lastName,
      team,
      roleId: role.id,
      id: selectedUser?.id,
    };
    updateUser(updateUserPayload);
    props.setSelectedUsers([]);
  };

  return (
    <>
      <AddAndEditUserModal
        teams={props.teams}
        isOpen={props.isEditUserOpen}
        setIsOpen={props.setIsEditUserOpen}
        onSubmit={onSubmit}
        defaultValues={selectedUser}
      />
    </>
  );
};

export default EditUser;
