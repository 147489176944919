import { create } from "zustand";
import { devtools } from "zustand/middleware";
import {
  createLanguagePairsSlice,
  LanguagePairsSliceT,
} from "./slices/languagePairsSlice";
import {
  createParsingRulesSlice,
  ParsingRulesSliceT,
} from "./slices/parsingRulesSlice";

type GlossaryStore = {
  isLoading: boolean;
} & LanguagePairsSliceT &
  ParsingRulesSliceT;

export const useGlossaryStore = create<GlossaryStore>()(
  devtools((set, get) => ({
    ...createParsingRulesSlice(set, get),
    ...createLanguagePairsSlice(set, get),
    isLoading: false,
  }))
);
