import React, { useEffect } from "react";
import { TabPanel, TabView } from "primereact/tabview";
import { Card } from "primereact/card";
import { useConsumerStore } from "../../../../shared/store/consumer";
import { GlossaryLanguagePairSelect } from "./GlossaryLanguagePairSelect";
import GlossaryProductionTermsTabPanelBody from "./GlossaryProductionTermsTabPanelBody";
import { useGlossaryStore } from "../../../../shared/store/glossary";

const GlossaryTabBody = () => {
  const selectedLanguagePair = useGlossaryStore(
    (state) => state.selectedLanguagePair
  );

  const { fetchCurrentConsumer, isLoading: isLoadingFetchCurrentConsumer } =
    useConsumerStore();

  useEffect(() => {
    fetchCurrentConsumer();
  }, []);

  return (
    <>
      {isLoadingFetchCurrentConsumer ? null : (
        <>
          <Card className="border mt-7">
            <GlossaryLanguagePairSelect />
          </Card>
          {selectedLanguagePair && (
            <Card className="border mt-3">
              <TabView>
                <TabPanel header="Production Terms">
                  <GlossaryProductionTermsTabPanelBody />
                </TabPanel>
              </TabView>
            </Card>
          )}
        </>
      )}
    </>
  );
};

export default GlossaryTabBody;
