interface Status {
  PUBLISHED: string;
  DELETED: string;
  DELETE: string;
  REVERTED: string;
  EDIT: string;
  ERROR: string;
  NEW_TERM: string;
  DEFAULT: string;
}

export const STATUS: Status = {
  PUBLISHED: "Published",
  DELETED: "Deleted",
  DELETE: "Delete Pending",
  REVERTED: "Delete Reverted",
  EDIT: "Edit Pending",
  NEW_TERM: "New Term",
  ERROR: "Error",
  DEFAULT: "N/A",
};
