import React from "react";

const TermsAndConditionsText = () => {
  return (
    <div>
      <h3 className="text-center mb-4 text-orange-accent">
        LANGUAGE I/O
        <br />
        SAAS SERVICES TERMS AND CONDITIONS
      </h3>
      <p>
        <span className="fw-bold text-orange-accent">
          LANGUAGE I/O SAAS SERVICES AGREEMENT
        </span>{" "}
        LANGUAGE IO, INC. (“COMPANY”) IS WILLING TO LICENSE SERVICES OR ACCESS
        TO SERVICES TO THE COMPANY OR ENTITY THAT WILL BE USING OR ACCESSING THE
        SERVICES AND THAT YOU REPRESENT AS AN EMPLOYEE OR AUTHORIZED AGENT
        (“CUSTOMER“) ONLY ON THE CONDITION THAT YOU ACCEPT ALL OF THE TERMS OF
        THIS SAAS SERVICES AGREEMENT (the “AGREEMENT”). PLEASE READ THE TERMS
        AND CONDITIONS OF THIS AGREEMENT CAREFULLY BEFORE ACCEPTING THIS
        AGREEMENT ON BEHALF OF YOUR COMPANY OR ENTITY. BY ACCEPTING THIS
        AGREEMENT, EITHER BY CLICKING A BOX INDICATING YOUR ACCEPTANCE,
        EXECUTING AN ORDER OR PROPOSAL, OR BY USING OR ACCESSING THE SERVICES,
        YOU AGREE TO THE TERMS OF THIS AGREEMENT.
      </p>
      <h4>1. CUSTOMER’S LICENSE, RESTRICTIONS AND RESPONSIBILITIES</h4>
      <p>
        1.1 Subject to these Terms and Conditions to the SaaS Services Agreement
        (these “<strong>Terms</strong>”), Company shall use commercially
        reasonable efforts to provide Customer a license to use the Services in
        the order form or plan selected or trial selected and accepted by
        Company. With respect to any Services that are distributed or provided
        to Customer for use on Customer premises or devices, Company hereby
        grants Customer a non-exclusive, non-transferable, non-sublicensable
        license to use such Services during the Term, as described below. Any
        release, revision, or enhancement to the Services or the result of any
        services performed hereunder that Company may furnish to Customer
        becomes a part of Services and is governed by these Terms These Terms
        also governs any renewal or additional purchases.
      </p>
      <p>
        1.2 Customer will not, directly or indirectly: reverse engineer,
        decompile, disassemble or otherwise attempt to discover the source code,
        object code or underlying structure, ideas, know-how or algorithms
        relevant to the Services or any software, documentation or data related
        to the Services; modify, translate, or create derivative works based on
        the Services (except to the extent expressly permitted by Company or
        authorized within the Services); use the Services for timesharing or
        service bureau purposes or otherwise for the benefit of a third party;
        and/or remove any proprietary notices or labels from the Services.
        Customer will not use the Service for any purpose other than for
        Customer’s own internal business purposes, make copies of the software,
        or exceed the scope of this license grant. Customer may not transfer,
        lend, lease, assign, and/or sublicense the Services, in whole or in
        part. Customer grants Company all right, title and interest in any
        suggestions Customer makes to Company related to the Services.
      </p>
      <p>
        1.3 Customer may not remove or export from the United States or allow
        the export or re-export of the Services, Services or anything related
        thereto, or any direct product thereof in violation of any restrictions,
        laws or regulations of the United States Department of Commerce, the
        United States Department of Treasury Office of Foreign Assets Control,
        or any other United States or foreign agency or authority. As defined in
        FAR section 2.101, the Services and documentation are “commercial items”
        and according to DFAR section 252.227-7014(a)(1) and (5) are deemed to
        be “commercial computer software” and “commercial computer software
        documentation.” Consistent with DFAR section 227.7202 and FAR section
        12.212, any use modification, reproduction, release, performance,
        display, or disclosure of such commercial software or commercial
        software documentation by the U.S. Government will be governed solely by
        the terms of this Agreement and will be prohibited except to the extent
        expressly permitted by the terms of this Agreement
      </p>
      <p>
        1.4 Customer represents, covenants, and warrants that Customer will use
        the Services only in compliance with all applicable laws and
        regulations. Customer hereby agrees to indemnify and hold harmless
        Company against any damages, losses, liabilities, settlements and
        expenses (including without limitation costs and attorneys’ fees) in
        connection with any claim or action that arises from an alleged
        violation of this <strong>Article 2</strong> or otherwise from
        Customer’s use of Services. Although Company has no obligation to
        monitor Customer’s use of the Services, Company may do so and may
        prohibit any use of the Services it believes may be (or alleged to be)
        in violation of the foregoing.
      </p>
      <p>
        1.5 Customer shall be responsible for obtaining and maintaining any
        equipment and ancillary services needed to connect to, access or
        otherwise use the Services, including, without limitation, modems,
        hardware, servers, software, operating systems, networking, web servers,
        the CRM and the like (collectively, “<strong>Equipment</strong>”).
        Customer shall also be responsible for maintaining the security of the
        Equipment, Customer account, passwords (including but not limited to
        administrative and user passwords) and files, and for all uses of
        Customer account or the Equipment with or without Customer’s knowledge
        or consent.
      </p>
      <h4>2. CONFIDENTIALITY; PROPRIETARY RIGHTS</h4>
      <p>
        2.1 Each party (the “<strong>Receiving Party</strong>”) understands that
        the other party (the “<strong>Disclosing Party</strong>”) has disclosed
        or may disclose business, technical or financial information relating to
        the Disclosing Party’s business (hereinafter referred to as “
        <strong>Proprietary Information</strong>” of the Disclosing Party).
        Proprietary Information of Company includes non-public information
        regarding features, functionality and performance of the Service.
        Proprietary Information of Customer includes non-public data provided by
        Customer to Company to enable the provision of the Services (“
        <strong>Customer Data</strong>”). The Receiving Party agrees: (a) to
        take reasonable precautions to protect such Proprietary Information, and
        (b) not to use (except in performance of the Services or as otherwise
        permitted herein) or divulge to any third person any such Proprietary
        Information. The Disclosing Party agrees that the foregoing shall not
        apply with respect to any information after five years following the
        disclosure thereof or any information that the Receiving Party can
        document (1) is or becomes generally available to the public, or (2) was
        in its possession or known by it prior to receipt from the Disclosing
        Party, or (3) was rightfully disclosed to it without restriction by a
        third party, or (4) was independently developed without use of any
        Proprietary Information of the Disclosing Party or (5) is required to be
        disclosed by law.
      </p>
      <p>
        2.2 Customer shall own all right, title and interest in and to the
        Customer Data. Company shall own and retain all right, title and
        interest in and to (a) the Services, including all improvements,
        enhancements or modifications thereto, (b) any software, applications,
        inventions or other technology developed in connection with Services or
        Support Services, and (c) all intellectual property rights related to
        any of the foregoing.
      </p>
      <p>
        2.3 Notwithstanding anything to the contrary, Company shall have the
        right to collect and analyze data and other information relating to the
        provision, use and performance of various aspects of the Services and
        related systems and technologies (including, without limitation,
        information concerning Customer Data and data derived therefrom), and
        Company will be free (during and after the term hereof) to (a) use such
        information and data to improve and enhance the Services and for other
        development, diagnostic and corrective purposes in connection with the
        Services and other Company offerings, and (b) disclose such data solely
        in aggregate or other de-identified form in connection with its
        business. No rights or licenses are granted except as expressly set
        forth herein.
      </p>
      <h4>3. PAYMENT OF FEES</h4>
      <p>
        3.1 Customer will pay Company the then applicable fees described in the
        order form or for the plan selected for the Services in accordance with
        the terms therein (the “<strong>Fees</strong>”). If Customer’s use of
        the Services requires the payment of additional fees (per the terms of
        this Agreement), Customer shall be billed for such usage and Customer
        agrees to pay the additional fees in the manner provided herein. Company
        reserves the right to change the Fees or applicable charges and to
        institute new charges and Fees at the end of the then-current renewal
        term, upon 30 days prior notice to Customer (which may be sent by
        email). If Customer believes that Company has billed Customer
        incorrectly, Customer must contact Company no later than 60 days after
        the closing date on the first billing statement in which the error or
        problem appeared, in order to receive an adjustment or credit. Inquiries
        should be directed to Company’s customer support department.
      </p>
      <p>
        3.2 Payment obligations are non-cancelable, and fees paid are
        non-refundable. Fees are to be paid prior to activation of Customer’s
        account. Company will give Customer at least 7 days prior notice that
        Customer’s account is overdue before suspending access to Services or
        terminating Customer’s license.
      </p>
      <p>
        3.3 Customer shall be responsible for all taxes associated with Services
        other than U.S. taxes based on Company’s net income. Customer agrees to
        pay or reimburse Company for all federal, state, dominion, provincial,
        or local sales, use, personal property, payroll, excise or other taxes,
        fees, or duties arising out of the Agreement or the transactions
        contemplated by the Agreement (other than taxes on the net income of
        Company).
      </p>
      <h4>4. TERM AND TERMINATION</h4>
      <div>
        <p>
          4.1 Subject to earlier termination as provided below, the license
          granted begins on the Subscription Effective Date listed in the order
          form or date plan selected by Customer or date a trial is selected by
          Customer and accepted by Company (the “<strong>Effective Date</strong>
          ”) and continues in effect through the license duration described in
          the order form, the plan selected or the trial as described (the “
          <strong>Subscription Term</strong>”), unless terminated earlier
          pursuant to any of the Agreement’s express provisions. On expiration
          of the Subscription Term and except for trial licenses as described
          below, the subscription shall automatically renew for additional
          successive terms unless either party provides notice of nonrenewal at
          least fifteen days before the end of the then-current term, or unless
          and until earlier terminated as provided under this Agreement.
          Furthermore, and for avoidance of doubt, should either party choose
          not to renew the subscription, and Customer comes to hold no active
          subscription, this Agreement will be deemed terminated.
        </p>
        <p>
          If a trial license has been granted to Customer, the Subscription Term
          is for 14 calendar days from the later of acceptance by Customer of
          this Agreement or provisioning of Customer’s account and does not
          renew without written extension by Company, which may be provided via
          email. Additional trial terms may apply and will be provided to
          Customer.
        </p>
      </div>
      <p>
        4.2 In addition to any other remedies it may have, either party may also
        terminate this Agreement upon 30 days’ notice (or without notice in the
        case of nonpayment), if the other party materially breaches any of the
        terms or conditions of this Agreement and does not cure such material
        breach within such 30 days notice. Upon any termination, Company will
        make all Customer Data available to Customer for electronic retrieval
        for a period of 30 days, but thereafter Company may, but is not
        obligated to, delete stored Customer Data. All sections of this
        Agreement which by their nature should survive termination will survive
        termination, including, without limitation, accrued rights to payment,
        confidentiality obligations, warranty disclaimers, and limitations of
        liability.
      </p>
      <h4>5. WARRANTY AND DISCLAIMER</h4>
      <p>
        Company shall use reasonable efforts consistent with prevailing industry
        standards to maintain the Services in a manner which minimizes errors
        and interruptions in the Services. Services may be temporarily
        unavailable for scheduled maintenance or for unscheduled emergency
        maintenance, either by Company or by third-party providers, or because
        of other causes beyond Company’s reasonable control, but Company shall
        use reasonable efforts to provide advance notice by email of any
        scheduled service disruption.{" "}
        <strong>
          However, Company does not warrant that the Services will be
          uninterrupted or error free; nor does it make any warranty as to the
          results that may be obtained from use of the Services. Except as
          expressly set forth in this section, the Services are provided “as is”
          and Company disclaims all warranties, express or implied, including,
          but not limited to, implied warranties of merchantability and fitness
          for a particular purpose and non-infringement.
        </strong>
      </p>
      <h4>6. INDEMNITY</h4>
      <p>
        Company will, at its expense, defend or settle any claim, suit or action
        (“<strong>Claim</strong>”) brought against Customer resulting from
        infringement by the Service of any United States patent or any copyright
        or misappropriation of any trade secret, and shall pay any final
        judgment awarded or settlements entered into on account of such Claim;
        provided, however, that Company must be promptly notified of any and all
        Claims related thereto and given reasonable assistance and the
        opportunity to assume sole control over defense and settlement. For
        avoidance of doubt, Company will not be responsible for any settlement
        that it does not approve in writing. The foregoing obligations do not
        apply with respect to portions or components of the Services (a) not
        supplied by Company, (b) made in whole or in part in accordance with
        Customer specifications, (c) that are modified after delivery by
        Company, (d) combined with other products, processes or materials where
        the alleged infringement relates to such combination, (e) where Customer
        continues allegedly infringing activity after being notified thereof or
        after being informed of modifications that would have avoided the
        alleged infringement, or (f) where Customer’s use of the Service is not
        strictly in accordance with this Agreement. If, due to a claim of
        infringement, the Services are held by a court of competent jurisdiction
        to be or are believed by Company to be infringing, Company may, at its
        option and expense (1) replace or modify the Service to be
        non-infringing provided that such modification or replacement contains
        substantially similar features and functionality, (2) procure the right
        for Customer to continue using the Service, or (3) if neither of the
        foregoing is commercially practicable, terminate this Agreement and
        Customer’s rights hereunder and provide Customer a refund of any
        prepaid, unused fees for the Service.{" "}
        <strong>
          This section states Company’s entire liability with respect to
          infringement of any patent, copyright, trade secret, proprietary right
          or other intellectual property of any third person or party.
        </strong>
      </p>
      <h4>7. LIMITATION OF LIABILITY</h4>
      <p>
        <strong>
          NOTWITHSTANDING ANYTHING TO THE CONTRARY, EXCEPT FOR BODILY INJURY OF
          A PERSON, COMPANY AND ITS SUPPLIERS (INCLUDING BUT NOT LIMITED TO ALL
          EQUIPMENT AND TECHNOLOGY SUPPLIERS), OFFICERS, AFFILIATES,
          REPRESENTATIVES, CONTRACTORS AND EMPLOYEES SHALL NOT BE RESPONSIBLE OR
          LIABLE WITH RESPECT TO ANY SUBJECT MATTER OF THIS AGREEMENT OR TERMS
          AND CONDITIONS RELATED THERETO UNDER ANY CONTRACT, NEGLIGENCE, STRICT
          LIABILITY OR OTHER THEORY: (A) FOR ERROR OR INTERRUPTION OF USE OR FOR
          LOSS OR INACCURACY OR CORRUPTION OF DATA OR COST OF PROCUREMENT OF
          SUBSTITUTE GOODS, SERVICES OR TECHNOLOGY OR LOSS OF BUSINESS; (B) FOR
          ANY INDIRECT, EXEMPLARY, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES;
          (C) FOR ANY MATTER BEYOND COMPANY’S REASONABLE CONTROL; OR (D) FOR ANY
          AMOUNTS THAT, TOGETHER WITH AMOUNTS ASSOCIATED WITH ALL OTHER CLAIMS,
          EXCEED THE FEES PAID BY CUSTOMER TO COMPANY FOR THE SERVICES UNDER
          THIS AGREEMENT IN THE 12 MONTHS PRIOR TO THE ACT THAT GIVES RISE TO
          THE LIABILITY, IN EACH CASE, WHETHER OR NOT COMPANY HAS BEEN ADVISED
          OF THE POSSIBILITY OF SUCH DAMAGES.
        </strong>
      </p>
      <h4>8. MISCELLANEOUS</h4>
      <p>
        If any provision of this Agreement is found to be unenforceable or
        invalid, that provision will be limited or eliminated to the minimum
        extent necessary so that this Agreement will otherwise remain in full
        force and effect and enforceable. This Agreement is not assignable,
        transferable or sublicensable by either party except to a successor
        assuming the rights and duties hereunder. This Agreement is the complete
        and exclusive statement of the mutual understanding of the parties and
        supersedes and cancels all previous written and oral agreements,
        communications and other understandings relating to the subject matter
        of this Agreement, and that all waivers and modifications must be in a
        writing signed by both parties, except as otherwise provided herein. No
        agency, partnership, joint venture, or employment is created as a result
        of this Agreement and Customer does not have any authority of any kind
        to bind Company in any respect whatsoever. In any action or proceeding
        to enforce rights under this Agreement, the prevailing party will be
        entitled to recover costs and attorneys’ fees. All notices under this
        Agreement will be in writing and will be deemed to have been duly given
        when received, if personally delivered; when receipt is electronically
        confirmed, if transmitted by email; the day after it is sent, if sent
        for next day delivery by recognized overnight delivery service; and upon
        receipt, if sent by certified or registered mail, return receipt
        requested. This Agreement shall be governed by the laws of the State of
        Wyoming without regard to its conflict of laws or provisions and the
        parties submit to the exclusive jurisdiction of the courts of the State
        of Wyoming for any claim or action hereunder. Any delay in the
        performance of any duties or obligations of either party (except the
        payment of money owed) will not be considered a breach of this Agreement
        if such delay is caused by an act, event, or occurrence beyond such
        party’s reasonable control, including, without limitation, acts of God,
        fires, floods, storms, landslides, epidemics, lightning, earthquakes,
        drought, blight, famine, quarantine, blockade, governmental acts or
        inaction, orders or injunctions, war, insurrection or civil strife,
        sabotage, explosions, labor strikes, work stoppages, acts of terror,
        issues arising from bugs or other problems in the software, firmware or
        hardware of Company’s suppliers, or outages or issues with upstream
        providers or network carriers (any such event, a “
        <strong>Force Majeure Event</strong>”), provided that such party uses
        reasonable efforts, under the circumstances, to notify the other party
        of the cause of such delay and to resume performance as soon as
        commercially feasible.
      </p>
      <div className="text-xs">Rev. 10-19-21</div>
    </div>
  );
};

export default TermsAndConditionsText;
