import React from "react";
import { Link } from "react-router-dom";
import heroImage from "shared/images/hero-1.png";

export const Home = (): JSX.Element => {
  return (
    <div className="container pt-5">
      <div className="row">
        <div className="col-md-6">
          <div className="row mb-4">
            <div className="col">
              <h1>Get the best quality translations over any channel</h1>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col">
              <p className="lead">
                Accurate, Company Specific, Real-Time Translation with Seamless
                API Integration
              </p>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col">
              <Link to="/sign-up" className="btn btn-secondary">
                Sign up for Free Trial
              </Link>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="ps-5 pt-5">
            <img
              src={heroImage}
              className="img-fluid"
              alt=""
              width="684"
              height="554"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
