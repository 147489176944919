import React from "react";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import "./style.css";
import TermsAndConditionsText from "./TermsAndConditionsText";

type propsT = {
  onTermsDecline: () => void;
  onTermsAgree: () => void;
};

const TermsAndConditions = ({ onTermsDecline, onTermsAgree }: propsT) => {
  return (
    <div className="row justify-content-center">
      <div className="col-lg-8">
        <h1 className="page-header">Terms & Conditions</h1>
        <Divider />
        <div className="max-h-30rem overflow-y-scroll my-5 py-1 pr-1">
          <TermsAndConditionsText />
        </div>
        <Divider />
        <div className="d-flex justify-content-between mt-5">
          <Button
            label="Decline"
            severity="danger"
            text
            onClick={onTermsDecline}
          />
          <Button label="I Agree" onClick={onTermsAgree} />
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
