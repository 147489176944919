export const routerPaths = {
  authenticated: {
    accountPage: "/account",
    settingsPage: "/settings",
    mtPortal: "/mt-portal",
  },
  unauthenticated: {
    signUp: "/sign-up",
    accountCreated: "/account-created",
    index: "/",
  },
};
