import { linguistnowAPI } from "./index";
import { Consumer } from "../../types/consumer/Consumer";
import {
  ParsingRuleResponse,
  ParsingRuleTransactionRequest,
  ParsingRuleUpdateResponse,
} from "../../types/ParsingRule";
import { ConsumerHasLocalePairResponse } from "../../types/ConsumerHasLocalePair";
import { ConsumerToggleData } from "../../types/consumer/ConsumerToggle";

export const ConsumerAPI = {
  getApiKey: async () => {
    return linguistnowAPI<{
      apiKey: string;
    }>({
      url: "linguistnow/consumer/apiKey",
    });
  },
  cycleApiKey: async () => {
    return linguistnowAPI<{ apiKey: string }>({
      url: "linguistnow/consumer/cycleApiKey",
      method: "POST",
      // if data is not specified for POST request, axios errors out with CORS
      data: null,
    });
  },
  getParsingRules: async (sourceLocaleId: number, targetLocaleId: number) => {
    return linguistnowAPI<ParsingRuleResponse>({
      url: `linguistnow/consumer/parsingRules`,
      params: {
        sourceLocaleId,
        targetLocaleId,
      },
    });
  },
  createParsingRules: async (data: ParsingRuleTransactionRequest) => {
    return linguistnowAPI<ParsingRuleUpdateResponse>({
      url: "linguistnow/consumer/parsingRules",
      method: "POST",
      data,
    });
  },
  getConsumerLocalePairs: async () => {
    return linguistnowAPI<ConsumerHasLocalePairResponse>({
      url: "linguistnow/consumer/consumerLocalePairs",
    });
  },
  getCurrentConsumer: async () => {
    return linguistnowAPI<Consumer>({
      url: "linguistnow/consumerToggle/currentConsumer",
    });
  },
  getConsumerToggleData: async () => {
    return linguistnowAPI<ConsumerToggleData>({
      url: "linguistnow/consumerToggle/selectData",
    });
  },
  postSelectedConsumer: async (data: string) => {
    return linguistnowAPI<Consumer>({
      url: "linguistnow/consumerToggle/selectConsumer",
      method: "POST",
      data: {
        id: data,
      },
    });
  },
};
