import { ConsumerHasLocalePair } from "../../../types/ConsumerHasLocalePair";
import { StateSliceCreator } from "../../utils/types";
import { ConsumerAPI } from "../../../api/api-golinguist/consumerAPI";

export type SelectedLanguagePairT = {
  sourceLocaleId: number;
  targetLocaleId: number;
  sourceLocaleName: string;
  targetLocaleName: string;
  isSourceLocaleRtl: boolean;
  isTargetLocaleRtl: boolean;
} | null;

export type LanguagePairsSliceT = {
  languagePairs: ConsumerHasLocalePair[];
  selectedLanguagePair: SelectedLanguagePairT | null;
  setSelectedLanguagePair: (languagePair: SelectedLanguagePairT) => void;
  fetchLanguagePairs: () => void;
  isLoading: boolean;
  resetSelectedLanguagePair: () => void;
  resetLanguagePairs: () => void;
};

export const createLanguagePairsSlice: StateSliceCreator<
  LanguagePairsSliceT
> = (set) => ({
  isLoading: false,
  languagePairs: [],
  selectedLanguagePair: null,
  setSelectedLanguagePair: (languagePair) => {
    set(() => ({ selectedLanguagePair: languagePair }));
  },
  fetchLanguagePairs: async () => {
    set(() => ({ isLoading: true }));

    const response = await ConsumerAPI.getConsumerLocalePairs();
    set(() => ({
      languagePairs: response?.data,
      isLoading: false,
    }));
  },
  resetSelectedLanguagePair: () => set(() => ({ selectedLanguagePair: null })),
  resetLanguagePairs: () => set(() => ({ languagePairs: [] })),
});
