import React from "react";
import { TableProps, TableContents } from "./TableContents";
import { Button } from "primereact/button";
import { useGlossaryStore } from "../../../../shared/store/glossary";
import {
  ParsingRuleUpdateResponse,
  UpdatedParsingRule,
} from "../../../../shared/types/ParsingRule";
import { GlossaryPublishReport } from "./GlossaryPublishReport";
import { STATUS } from "../../utils/GlossaryTableConstants";
import { getDataTestIdPtObject } from "../../../../shared/functions/utils";
import { ACTION_STATUS } from "../../../../shared/constants";

interface ConfirmationProps extends TableProps {
  setShowEditConfirmation: React.Dispatch<React.SetStateAction<boolean>>;
  isPublished: boolean;
  setIsPublished: React.Dispatch<React.SetStateAction<boolean>>;
}

export const GlossaryEditConfirmation = (props: ConfirmationProps) => {
  const { postParsingRules, selectedLanguagePair, fetchParsingRules } =
    useGlossaryStore();

  const [postResponse, setPostResponse] =
    React.useState<ParsingRuleUpdateResponse | null>(null);

  const processResponse = async (
    postResponseData: ParsingRuleUpdateResponse
  ) => {
    if (postResponseData) {
      const modifiedParsingRulesCopy = props.modifiedParsingRules;

      postResponseData.results.forEach(
        ({ error, parsingRule }: UpdatedParsingRule, index: number) => {
          if (error) {
            modifiedParsingRulesCopy[index].error = error;
            modifiedParsingRulesCopy[index].status = STATUS.ERROR;
          } else {
            modifiedParsingRulesCopy[index].status =
              parsingRule.status === ACTION_STATUS.delete
                ? STATUS.DELETED
                : STATUS.PUBLISHED;
          }
        }
      );
      // update modified parsing rules so the publish report data will contain errors
      await props.setModifiedParsingRules(modifiedParsingRulesCopy);
    }
  };

  const modifiedTermsCountDisplayText = React.useMemo(() => {
    function getCountString(num: number, text: string) {
      if (num > 0) {
        return `(${num}) ${text} terms`;
      }

      return "";
    }

    const countObj = {
      New: 0,
      Edited: 0,
      Deleted: 0,
    };

    props.modifiedParsingRules.forEach(({ status }) => {
      if (status === STATUS.DELETE) {
        countObj.Deleted++;
      } else if (status === STATUS.EDIT) {
        countObj.Edited++;
      } else {
        countObj.New++;
      }
    });

    return Object.entries(countObj)
      .map(([key, value]) => {
        return getCountString(value, key);
      })
      .filter((item) => item)
      .join(", ");
  }, [props.modifiedParsingRules]);

  if (props.isPublished) {
    return (
      <GlossaryPublishReport
        displayParsingRules={props.modifiedParsingRules}
        setDisplayParsingRules={props.setModifiedParsingRules}
        modifiedParsingRules={props.modifiedParsingRules}
        setModifiedParsingRules={props.setModifiedParsingRules}
        showEditConfirmation={false}
        setShowEditConfirmation={props.setShowEditConfirmation}
        isPublished={props.isPublished}
        setIsPublished={props.setIsPublished}
        response={postResponse}
      />
    );
  } else {
    return (
      <div>
        <h4 className="confirmationHeading">
          <strong>Review updates to glossary</strong>
        </h4>
        <p {...getDataTestIdPtObject("modificationCount")}>
          {modifiedTermsCountDisplayText}
        </p>

        <TableContents
          displayParsingRules={props.modifiedParsingRules}
          setDisplayParsingRules={props.setModifiedParsingRules}
          modifiedParsingRules={props.modifiedParsingRules}
          setModifiedParsingRules={props.setModifiedParsingRules}
          showEditConfirmation={props.showEditConfirmation}
        />

        <div className="d-flex justify-content-end">
          <Button
            label="Cancel changes"
            className="m-3 cancelButton"
            onClick={async () => {
              if (selectedLanguagePair) {
                await props.setModifiedParsingRules([]);
                await fetchParsingRules(
                  selectedLanguagePair.sourceLocaleId,
                  selectedLanguagePair.targetLocaleId
                );
                await props.setShowEditConfirmation(false);
              }
            }}
            pt={{ root: getDataTestIdPtObject("cancelChangesBtn") }}
          />
          <Button
            label="Publish Changes to Glossary"
            className="m-3"
            onClick={async () => {
              if (selectedLanguagePair) {
                const response: ParsingRuleUpdateResponse | undefined =
                  await postParsingRules(
                    props.modifiedParsingRules,
                    selectedLanguagePair
                  );
                if (response) {
                  await setPostResponse(response);
                  await processResponse(response);
                  await props.setIsPublished(true);
                }
              }
            }}
            pt={{ root: getDataTestIdPtObject("publishChangesBtn") }}
          />
        </div>
      </div>
    );
  }
};
