import { apiPhase1 } from "./index";
import { ConsumerHasLocale } from "../../types/ConsumerHasLocale";
import { PostConsumerHasLocaleFormalityModelT } from "../../types/UpdateConsumerHasLocaleFormality";

const getConsumerHasLocalePairsUrl = (consumerId: string) =>
  `trialsub/consumers/${consumerId}/consumerhaslocales/`;

export const TrialSubAPI = {
  getConsumerHasLocales: async (consumerId: string) => {
    return apiPhase1<{
      consumerHasLocales: ConsumerHasLocale[] | null;
    }>({
      url: getConsumerHasLocalePairsUrl(consumerId),
    });
  },
  createAndUpdateConsumerHasLocales: async (
    consumerId: string,
    consumerHasLocales: PostConsumerHasLocaleFormalityModelT[]
  ) => {
    return apiPhase1<{
      consumerHasLocales: ConsumerHasLocale[];
      status: string;
    }>({
      url: getConsumerHasLocalePairsUrl(consumerId),
      method: "POST",
      data: { consumerHasLocales },
    });
  },
  createAutoLocalePairs: async (consumerId: string, localeId: number) => {
    return apiPhase1<{
      numberOfLocalePairsCreated: number;
      status: string;
    }>({
      url: `trialsub/consumers/${consumerId}/autolocalepairs/`,
      method: "POST",
      data: {
        sourceLocaleId: localeId,
      },
    });
  },
};

export default TrialSubAPI;
