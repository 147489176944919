import React from "react";

export const TabHeader = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <h1
      className={`tab-header text-lg fw-bold text-gray-900 ${className || ""}`}
    >
      {children}
    </h1>
  );
};

export const FieldCategoryHeader = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <h2
      className={`category-header mt-4 text-sm fw-bold text-gray-700 ${
        className || ""
      }`}
    >
      {children}
    </h2>
  );
};

export const FieldHeader = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <h3 className={`field-label mt-3 text-sm text-gray-700 ${className || ""}`}>
      {children}
    </h3>
  );
};
