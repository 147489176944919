import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { AccountSignUp } from "../pages/account-sign-up/components/AccountSignUp";
import SettingsPage from "../pages/settings/components/Settings";
import { OuterContainer } from "../shared/components/OuterContainer";
import { AccountCreated } from "../pages/account-created/components/AccountCreated";
import AccountHome from "../pages/account/components/AccountHome";
import { Home } from "../pages/home/components/Home";
import MTPortal from "../pages/machine-tanslation-portal";
import { routerPaths } from "./paths";
import PageNotFound from "../pages/not-found";
import { useConsumerStore } from "../shared/store/consumer";
import { Permission, PermissionGroup } from "../shared/types/UserPermission";
import { SERVER_CODE_MT_PORTAL_URL } from "../shared/components/Header/components/Nav/NavServerCode";
const AppRouter = () => {
  const { pathname } = useLocation();
  const { currentConsumer, hasPermission } = useConsumerStore();

  const hasCurrentPathAccess = () => {
    if (pathname === routerPaths.authenticated.settingsPage) {
      // the only path we check this for is currently Settings page path
      if (!hasPermission(PermissionGroup.SETTINGS, Permission.VIEW)) {
        return false;
      }
    }
    return true;
  };

  useEffect(() => {
    if (currentConsumer.permissions.length > 0 && !hasCurrentPathAccess()) {
      // redirect to MT Portal if user does not have permission to access a page for current consumer CN-1061
      window.location.replace(SERVER_CODE_MT_PORTAL_URL);
    }
  }, [currentConsumer]);

  return (
    <Routes>
      <Route path="*" element={<PageNotFound />} />
      <Route
        index
        path={routerPaths.unauthenticated.index}
        element={<OuterContainer child={<Home />} />}
      />
      <Route
        path={routerPaths.unauthenticated.signUp}
        element={<OuterContainer child={<AccountSignUp isPilot={true} />} />}
      />
      <Route
        path={routerPaths.unauthenticated.accountCreated}
        element={<OuterContainer child={<AccountCreated />} />}
      />
      <Route
        path={routerPaths.authenticated.accountPage}
        element={<AccountHome />}
      />
      <Route
        path={routerPaths.authenticated.settingsPage}
        element={<SettingsPage />}
      />
      <Route
        path={routerPaths.authenticated.mtPortal}
        element={<OuterContainer child={<MTPortal />} />}
      />
    </Routes>
  );
};

export default AppRouter;
