import React from "react";
import { Button } from "primereact/button";
import { getDataTestIdPtObject } from "../../../../shared/functions/utils";
import { User } from "../../../../shared/types/User";
import {
  Permission,
  PermissionGroup,
} from "../../../../shared/types/UserPermission";
import { useConsumerStore } from "../../../../shared/store/consumer";
import { USER_STATUS_NAME_MAPPING } from "../../../../shared/constants";
import { UserAPI } from "../../../../shared/api/api-golinguist/userAPI";

type PropsT = {
  selectedUsers?: User[];
  setSelectedUsers: React.Dispatch<React.SetStateAction<User[]>>;
  setIsAddUserOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setShowDisableConfirmationMessage: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setIsEditUserOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsInviteResentConfirmationVisible: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setInviteResentToEmails: React.Dispatch<React.SetStateAction<string[]>>;
};
const Header = (props: PropsT) => {
  const { hasPermission, currentConsumer } = useConsumerStore();

  // at this time we only support editing one user
  const isEditUserDisabled = props.selectedUsers?.length != 1;

  const noUsersSelected = props.selectedUsers?.length == 0;

  const allSelectedUsersHaveStatusPending = props.selectedUsers?.every(
    (user) => user.status === USER_STATUS_NAME_MAPPING.PENDING
  );

  const resendInviteDisabled =
    noUsersSelected || !allSelectedUsersHaveStatusPending;

  const onResendInvite = async () => {
    const userIds = props.selectedUsers?.map((user) => user.id);
    const userEmails = props.selectedUsers?.map((user) => user.email) || [];
    if (userIds) {
      UserAPI.resendEmailInvite(userIds).then(() => {
        props.setInviteResentToEmails(userEmails);
        props.setIsInviteResentConfirmationVisible(true);
      });
      props.setSelectedUsers([]);
    }
  };

  return (
    <div className="d-flex align-items-center my-4">
      <div className="flex align-items-center gap-2">
        {!currentConsumer.isSsoUser && (
          <div style={{ minWidth: 80 }}>
            {props.selectedUsers?.length} selected
          </div>
        )}
        {hasPermission(PermissionGroup.USER, Permission.EDIT) && (
          <Button
            disabled={isEditUserDisabled}
            size="small"
            onClick={() => props.setIsEditUserOpen(true)}
            pt={{ root: getDataTestIdPtObject("editUserBtn") }}
          >
            Edit User
          </Button>
        )}
        {hasPermission(PermissionGroup.USER, Permission.DELETE) && (
          <Button
            disabled={noUsersSelected}
            size="small"
            onClick={() => props.setShowDisableConfirmationMessage(true)}
            pt={{ root: getDataTestIdPtObject("disableUserBtn") }}
          >
            Disable
          </Button>
        )}
        {hasPermission(PermissionGroup.USER, Permission.EDIT) && (
          <Button
            disabled={resendInviteDisabled}
            size="small"
            onClick={onResendInvite}
            pt={{ root: getDataTestIdPtObject("resentEmailInviteBtn") }}
          >
            Resend Invite
          </Button>
        )}
      </div>
      {hasPermission(PermissionGroup.USER, Permission.ADD) && (
        <Button
          className="ml-auto"
          onClick={() => props.setIsAddUserOpen((val) => !val)}
          pt={{ root: getDataTestIdPtObject("toggleAddUserForm") }}
        >
          <i className="pi pi-plus mr-2"></i>
          Add user
        </Button>
      )}
    </div>
  );
};

export default Header;
