import React, { useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { emailRegex, whitespaceOnly } from "../../../../shared/regex";
import { Controller, useForm } from "react-hook-form";
import { Dropdown } from "primereact/dropdown";
import {
  getDataTestIdPtObject,
  validateEmailAddress,
} from "../../../../shared/functions/utils";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { Role, UserBase } from "../../../../shared/types/User";
import { useUsersStore } from "../../../../shared/store/users";
import { Button } from "primereact/button";
import DropdownWithAddNew from "./DropdownWithAddNew";
import { ValueOf } from "../../../../shared/types/util";

export type UserFormData = Omit<UserBase, "id" | "status"> & {
  role: Role;
};

const AddAndEditUserModal = (props: {
  isOpen: boolean;
  setIsOpen: (state: boolean) => void;
  onSubmit: (data: UserFormData) => void;
  teams: string[];
  defaultValues?: {
    firstName: string;
    lastName: string;
    id: number;
    team?: string;
    role: Role;
  };
}) => {
  const { roles, fetchRoles } = useUsersStore();

  useEffect(() => {
    fetchRoles();
  }, [roles]);

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm<UserFormData>({
    defaultValues: props.defaultValues,
  });

  useEffect(() => {
    Object.entries(props.defaultValues || {}).forEach(([key, val]) => {
      setValue(key as keyof UserFormData, val as ValueOf<UserFormData>);
    });
  }, [props.defaultValues]);

  const onFormReset = () => {
    reset();
    props.setIsOpen(false);
  };

  const getTextInputWithController = (
    fieldName: keyof Omit<UserFormData, "role">,
    requiredErrorMessage?: string,
    pattern?: RegExp,
    patternErrorMessage?: string,
    disabled?: boolean
  ) => {
    const validateFieldIsNotEmpty = (value: string | undefined) => {
      // for all fields, validate for empty string
      return (
        !whitespaceOnly.test(value?.toString() || "") || "Can not be empty"
      );
    };
    const validateEmail = (value: string | undefined) => {
      if (fieldName === "email" && value) {
        return validateEmailAddress(value);
      }
      return true;
    };

    return (
      <Controller
        name={fieldName}
        control={control}
        rules={{
          required: requiredErrorMessage,
          ...(pattern && {
            pattern: {
              value: pattern,
              message: patternErrorMessage || "Does not match required pattern",
            },
          }),
          validate: (value) => {
            if (fieldName === "email" && value) {
              return validateFieldIsNotEmpty(value) && validateEmail(value);
            }
            return validateFieldIsNotEmpty(value);
          },
        }}
        render={({
          field: { onChange, onBlur, value, name },
          fieldState: { error },
        }) => (
          <InputText
            disabled={disabled}
            id={name}
            onBlur={onBlur}
            onChange={onChange}
            value={value}
            className={classNames({
              "p-invalid": error,
              "w-100": true,
              "p-inputtext-sm": true,
            })}
            pt={{ root: getDataTestIdPtObject(`addUserInput-${fieldName}`) }}
          />
        )}
      />
    );
  };
  const getFormErrorMessage = (fieldName: keyof UserFormData) => {
    return (
      <div className="p-error text-xs" style={{ maxWidth: 250 }}>
        {errors[fieldName]?.message}
      </div>
    );
  };

  const footer = (
    <div>
      <Button
        label="Cancel"
        severity="secondary"
        outlined
        onClick={onFormReset}
        pt={{ root: getDataTestIdPtObject("cancelCreateUserBtn") }}
      />
      <Button
        className="mr-0"
        label="Save"
        onClick={handleSubmit((data) => {
          props.onSubmit(data);
          onFormReset();
        })}
        pt={{ root: getDataTestIdPtObject("saveUserBtn") }}
      />
    </div>
  );

  return (
    <Dialog
      header={`${props.defaultValues ? "Edit" : "Add New"} User`}
      visible={props.isOpen}
      onHide={onFormReset}
      footer={footer}
    >
      <form className="d-flex mt-3 mb-3 flex-wrap justify-content-start">
        <div className="mr-4">
          <label className="d-block" htmlFor="firstName">
            First Name
          </label>
          {getTextInputWithController("firstName", "First name is required")}
          {getFormErrorMessage("firstName")}
        </div>
        <div className="mr-4">
          <label className="d-block" htmlFor="lastName">
            Last Name
          </label>
          {getTextInputWithController("lastName", "Last name is required")}
          {getFormErrorMessage("lastName")}
        </div>
        <div className="mr-4">
          <label className="d-block" htmlFor="email">
            Email
          </label>
          {getTextInputWithController(
            "email",
            "Email is required",
            emailRegex,
            "Email is invalid",
            !!props.defaultValues
          )}
          {getFormErrorMessage("email")}
        </div>
        <div className="mr-4">
          <label className="d-block" htmlFor="team">
            Team
          </label>
          <Controller
            name="team"
            control={control}
            render={({ field: { name, value, ref, onChange } }) => (
              <DropdownWithAddNew
                name={name}
                className="w-100 p-inputtext-sm"
                options={props.teams}
                value={value}
                focusInputRef={ref}
                onChangeCb={onChange}
                pt={{
                  select: getDataTestIdPtObject(`teamSelect`),
                  list: getDataTestIdPtObject("teamList"),
                }}
              />
            )}
          />
        </div>
        <div style={{ width: "179px" }}>
          <label className="d-block" htmlFor="role">
            Role
          </label>
          <Controller
            name="role"
            control={control}
            rules={{
              required: "Role is required",
            }}
            render={({
              field: { name, value, ref, onChange },
              fieldState: { error },
            }) => (
              <Dropdown
                id={name}
                className={classNames({
                  "p-invalid": error,
                  "w-100": true,
                  "p-inputtext-sm": true,
                })}
                options={roles}
                value={value}
                focusInputRef={ref}
                onChange={(e) => onChange(e.value)}
                pt={{
                  select: getDataTestIdPtObject(`addUserRoleSelect`),
                  list: getDataTestIdPtObject("addUserRoleList"),
                }}
              />
            )}
          />
          {getFormErrorMessage("role")}
        </div>
      </form>
    </Dialog>
  );
};

export default AddAndEditUserModal;
