import React, { Component, ErrorInfo, ReactNode } from "react";
import { OuterContainer } from "shared/components/OuterContainer";
import { OverlayError } from "shared/components/OverlayError";
import { hideLoadingOverlay } from "shared/functions/utils";
import useAppErrorsState from "../store/app-errors";

interface Props {
  children: ReactNode;
}

//Inspired by https://react-typescript-cheatsheet.netlify.app/docs/basic/getting-started/error_boundaries/
class ErrorBoundary extends Component<Props> {
  public static getDerivedStateFromError(e: Error) {
    console.error(e);
    // Update state so the next render will show the fallback UI.
    useAppErrorsState.getState().setRuntimeError();
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    console.error("Uncaught error:", error, errorInfo);
  }

  public render(): React.ReactNode {
    if (useAppErrorsState.getState().hasRuntimeError) {
      hideLoadingOverlay();
      return <OuterContainer child={<OverlayError></OverlayError>} />;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
